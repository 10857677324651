import * as _ from 'lodash';
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CommonUtility } from "../common/utility/common.utility";
import { ResponseConstant } from "../common/constant/response.constant";
import { PreferenceConstant } from '../common/constant/preference.constant';
import { MessageConstant } from "../common/constant/message.constant";
import { PreferenceService } from '../common/service/preference.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter } from "rxjs";

@Component({
    selector: 'preferences',
    templateUrl: './preference.component.html',
    styleUrls: ['./preference.component.scss'],
    providers: [PreferenceService],
    encapsulation: ViewEncapsulation.None
})

export class PreferenceComponent implements OnInit {

    initialPreferencesData = {
        markets: [], productTypes: [], therapeuticAreas: []
    };
    therapeuticAreasInitial = [];
    preferenceConstant = PreferenceConstant;
    isRequestInProgress = false;
    preferenceForm: FormGroup;

    isMarketPreferenceChanged = false;
    isProductTypePreferenceChanged = false;
    isTherapeuticAreaPreferenceChanged = false;
    countOfMarketsSelected = 0;
    countOfProductTypesSelected = 0;
    isApplyAndResetEnabled = false;
    userId = '';
    dataLoaded = false;

    constructor(private preferenceService: PreferenceService, private snackbar: MatSnackBar,
        private fb: FormBuilder, private router: Router) {
    }

    ngOnInit(): void {
        this.userId = CommonUtility.getUserIdFromLocalStorage();
        this.preferenceForm = this.fb.group({
            markets: this.fb.array([]),
            productTypes: this.fb.array([]),
            therapeuticAreas: this.fb.array([]),
            therapeuticAreaSearchInput: this.fb.control('')
        })
        this.getPreferences();

        this.preferenceForm.controls['therapeuticAreaSearchInput'].valueChanges.pipe(filter(res => res.length === 0 || res.length > 0)
            , debounceTime(100)
            , distinctUntilChanged()).subscribe({
                next: ((searchTerm) => {
                    const filteredItems = this.therapeuticAreasInitial.filter((item) => {
                        return _.startsWith(_.toLower(item.name), _.toLower(searchTerm));
                    })
                    this.therapeuticAreas.clear();
                    _.each(filteredItems, (item) => {
                        this.therapeuticAreas.push(this.fb.group(item));
                    })
                })
            })
    }

    onMarketChanges(): void {
        const { markets } = this.initialPreferencesData;
        this.countOfMarketsSelected = this.getSelectedAttributes(this.markets.value).length;
        this.isMarketPreferenceChanged
            = this.getSelectedAttributes(this.markets.value).length != this.getSelectedAttributes(markets).length;
        this.isApplyAndResetEnabled
            = (this.isMarketPreferenceChanged || this.isProductTypePreferenceChanged || this.isTherapeuticAreaPreferenceChanged)
            && this.countOfMarketsSelected > 0 && this.countOfProductTypesSelected > 0;
    }

    onProductTypeChanges(): void {
        const { productTypes } = this.initialPreferencesData;
        this.countOfProductTypesSelected = this.getSelectedAttributes(this.productTypes.value).length;
        this.isProductTypePreferenceChanged
            = this.getSelectedAttributes(this.productTypes.value).length != this.getSelectedAttributes(productTypes).length;
        this.isApplyAndResetEnabled
            = (this.isMarketPreferenceChanged || this.isProductTypePreferenceChanged || this.isTherapeuticAreaPreferenceChanged)
            && this.countOfMarketsSelected > 0 && this.countOfProductTypesSelected > 0;
    }

    onTherapeuticAreaChanges(): void {
        const { therapeuticAreas } = this.initialPreferencesData;
        _.each(this.therapeuticAreasInitial, (item) => {
            const itemExisting = _.find(this.therapeuticAreas.value, { id: item.id });
            if (!_.isEmpty(itemExisting)) {
                item.checked = itemExisting.checked;
            }
        });
        this.isTherapeuticAreaPreferenceChanged
            = this.getSelectedAttributes(this.therapeuticAreas.value).length != this.getSelectedAttributes(therapeuticAreas).length;
        this.isApplyAndResetEnabled
            = (this.isMarketPreferenceChanged || this.isProductTypePreferenceChanged || this.isTherapeuticAreaPreferenceChanged)
            && this.countOfMarketsSelected > 0 && this.countOfProductTypesSelected > 0;
    }

    getSelectedAttributes(items: any): Record<string, any> {
        return _.filter(items, (item) => {
            return item.checked === true;
        })
    }

    get markets(): FormArray {
        return this.preferenceForm.controls['markets'] as FormArray;
    }

    get productTypes(): FormArray {
        return this.preferenceForm.controls['productTypes'] as FormArray;
    }

    get therapeuticAreas(): FormArray {
        return this.preferenceForm.controls['therapeuticAreas'] as FormArray;
    }


    //Method to fetch all the preference list
    getPreferences(): void {
        this.isRequestInProgress = true;
        this.dataLoaded = false;
        this.preferenceService.getPreferences(this.userId).subscribe({
            next: (response) => {
                this.isRequestInProgress = false;
                if (CommonUtility.isSuccessResponse(response[ResponseConstant.KEY_STATUS_CODE])) {
                    const { data } = response;
                    this.initialPreferencesData = _.cloneDeep(data);
                    const { markets, productTypes, therapeuticAreas } = data;
                    this.therapeuticAreasInitial = _.cloneDeep(therapeuticAreas);
                    _.each(markets, (item) => {
                        this.markets.push(this.fb.group(item))
                    })
                    _.each(productTypes, (item) => {
                        this.productTypes.push(this.fb.group(item))
                    })
                    _.each(therapeuticAreas, (item) => {
                        this.therapeuticAreas.push(this.fb.group(item))
                    })
                    this.dataLoaded = true;
                } else {
                    CommonUtility.openSnackBar(this.snackbar, response[ResponseConstant.KEY_MESSAGE]);
                }
            },
            error: (err) => {
                this.isRequestInProgress = false;
                CommonUtility.openSnackBar(this.snackbar, MessageConstant.MESSAGE_SEARCH_HISTORY_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR);
            },
        });
    }

    onApply(): void {
        const { markets, productTypes, therapeuticAreas } = this.preferenceForm.value;
        const preferencesRequired = {
            markets: this.getSelectedAttributes(markets),
            productTypes: this.getSelectedAttributes(productTypes),
            therapeuticAreas: this.getSelectedAttributes(therapeuticAreas),
        }

        this.preferenceService.updatePreferences(this.userId, preferencesRequired).subscribe({
            next: (response) => {
                this.isRequestInProgress = false;
                if (CommonUtility.isSuccessResponse(response[ResponseConstant.KEY_STATUS_CODE])) {
                    CommonUtility.openSnackBar(this.snackbar, response[ResponseConstant.KEY_MESSAGE]);
                    localStorage.setItem('hasSetPreferences', 'true');
                    this.router.navigateByUrl('/intel-reg/regulatory')
                } else {
                    CommonUtility.openSnackBar(this.snackbar, response[ResponseConstant.KEY_MESSAGE]);
                }
            },
            error: (err) => {
                this.isRequestInProgress = false;
                CommonUtility.openSnackBar(this.snackbar, MessageConstant.MESSAGE_SEARCH_HISTORY_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR);
            },
        });
    }

    onReset(): void {
        this.markets.clear();
        this.productTypes.clear();
        this.therapeuticAreas.clear();
        const { markets, productTypes, therapeuticAreas } = this.initialPreferencesData;
        _.each(markets, (item) => {
            this.markets.push(this.fb.group({ ...item }))
        })

        _.each(productTypes, (item) => {
            this.productTypes.push(this.fb.group({ ...item }))
        })
        _.each(therapeuticAreas, (item) => {
            this.therapeuticAreas.push(this.fb.group({ ...item }))
        })
    }
}
