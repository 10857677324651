/**
 * Created by nagaraj.ambig on 3/5/2021 10:28 PM.
 */
import {KeycloakService} from "keycloak-angular";
import {appConfig, keycloakConfig} from "../../../environments/environment";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    if (appConfig.enableKeycloak) {
        return () =>
            keycloak.init({
                config: keycloakConfig,
                initOptions: {
                    checkLoginIframe: true,
                    checkLoginIframeInterval: 25
                },
                loadUserProfileAtStartUp: true
            });
    } else {
        return () =>
            keycloak.init({
                config: keycloakConfig,
                initOptions: {
                    checkLoginIframe: false,
                    checkLoginIframeInterval: 25
                },
                loadUserProfileAtStartUp: false
            });
    }
}
