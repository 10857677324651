import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';
import {SharedModule} from '../common/module/shared.module';
import {CommonModule} from "@angular/common";
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatMenuModule} from '@angular/material/menu';
import {
    FollowUnfollowSnackbarComponent
} from '../common/component/follow-unfollow-snackbar/follow-unfollow-snackbar.component';
import {MatButtonModule} from '@angular/material/button';

const routes: Routes = [
    {
        path: '', component: HomeComponent,
        children: [
            //please redirect to search or ri-dashboard if you are working for labelling search or ri-dashboard vice versa
            {path: '', redirectTo: 'regulatory', pathMatch: 'full'},
            {
                path: 'regulatory',
                loadChildren: () => import('./regulatory-intelligence/regulatory-intelligence.module')
                    .then(m => m.RegulatoryIntelligenceModule)
                    .catch(err => console.error(err))
            },
            {
                path: 'labelling',
                loadChildren: () => import('./labelling-intelligence/labelling-intelligence.module')
                    .then(m => m.LabellingIntelligenceModule)
                    .catch(err => console.error(err))
            },
            {
                path: 'haq',
                loadChildren: () => import('./haq/haq.module')
                    .then(m => m.HaqModule)
                    .catch(err => console.error(err))
            },
        ]
    }
];

@NgModule({
    declarations: [
        HomeComponent,
        FollowUnfollowSnackbarComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        CommonModule,
        MatIconModule,
        MatBadgeModule,
        FlexLayoutModule,
        MatMenuModule,
        MatButtonModule
    ],
    exports: [
        HomeComponent,
    ]
})

export class HomeModule {
}
