import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExportLabelEventSharedService {

    private exportLabelEvent = new BehaviorSubject<Record<string, any>>({});
    public exportLabelEvent$ = this.exportLabelEvent.asObservable();

    private exportLabelViewEvent = new BehaviorSubject<boolean>(false);
    public exportLabelViewEvent$ = this.exportLabelViewEvent.asObservable();

    private exportLabelViewEventFromHome = new BehaviorSubject<boolean>(false);
    public exportLabelViewEventFromHome$ = this.exportLabelViewEventFromHome.asObservable();

    setExportLabelEvent(value: Record<string, any>): void {
        this.exportLabelEvent.next(value);
    }

    setExportLabelViewEvent(value: boolean): void {
        this.exportLabelViewEvent.next(value);
    }
    setExportLabelViewEventFrom(value: boolean): void {
        this.exportLabelViewEventFromHome.next(value);
    }
}
