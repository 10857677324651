import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakService} from "keycloak-angular";
import {CommonUtility} from "../../utility/common.utility";
import {PreferenceService} from "../../service/preference.service";
import {lastValueFrom} from "rxjs";
import {CommonConstant} from "../../constant/common.constant";
import {AuthenticationService} from "../../service/authentication.service";
import {LocalStorageConstant} from "../../constant/local-storage.constant";

@Injectable({
    providedIn: 'root'
})
export class PreferenceGuard implements CanActivate {
    constructor(private router: Router,
                protected readonly keycloak: KeycloakService,
                private preferenceService: PreferenceService,
                private authenticationService: AuthenticationService) {
    }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<any> {

        if (await this.keycloak.isLoggedIn()) {
            let hasSetPreferences = CommonUtility.isEqualToString(localStorage.getItem('hasSetPreferences'), 'true');
            const token = CommonUtility.getTokenFromLocalStorage();
            const id = this.keycloak.getKeycloakInstance().subject;
            CommonUtility.setUserDetails({[CommonConstant.KEY_USER_ID]: id});
            //TODO: commenting for now, to fix the build issue
            /*if (!token) {
                const response = await lastValueFrom(this.authenticationService.getToken(id));
                CommonUtility.setUserDetails({[CommonConstant.KEY_TOKEN]: response.data.token});
            }*/
            if (!hasSetPreferences) {
                const response = await lastValueFrom(this.preferenceService.getPreferences(id));
                hasSetPreferences = response.data.hasSetPreferences;
            }
            if (hasSetPreferences) {
                localStorage.setItem('hasSetPreferences', 'true');
                let rUrl = state.url.split("rUrl=")[1];
                rUrl = this.getModuleHomeUrl(rUrl);
                if (rUrl) {
                    this.router.navigateByUrl(rUrl);
                } else {
                    this.router.navigateByUrl('/intel-reg/labelling/search/advanced');
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    //once logged in, redirect to module home page
    getModuleHomeUrl(rUrl: string): string {
        const lastActiveModuleBeforeLogout = CommonUtility.getItemFromLocalStorage(LocalStorageConstant.KEY_LAST_ACTIVE_MODULE_BEFORE_LOGOUT);
        let rUrlRequired = decodeURIComponent(rUrl);
        if (lastActiveModuleBeforeLogout) {
            if (CommonUtility.isEqualToString('haq', lastActiveModuleBeforeLogout)) {
                rUrlRequired = '/intel-reg/haq/queries/available';
            } else if (CommonUtility.isEqualToString('regulatory', lastActiveModuleBeforeLogout)) {
                rUrlRequired = '/intel-reg/regulatory/ri-dashboard/product-approvals';
            } else if (CommonUtility.isEqualToString('labelling', lastActiveModuleBeforeLogout)) {
                rUrlRequired = '/intel-reg/labelling/search/advanced';
            }
        }
        return rUrlRequired;
    }
}
