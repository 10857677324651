import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
// import { NGXLogger } from 'ngx-logger';
// import { QuestionBase } from 'src/app/models/question-base';
import { LITERALS as L } from 'src/app/common/constant/literals';
import { SnackLabel as snackLabel  } from "../../constant/labels";
// import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

// export interface DialogData {
//   dialogTitle: string;
//   columns: any;

//   /**
//    * In case of confirmation dialog like deletion, updation
//    */
//   confirmation_message: string;
//   cancelBtnLabel: string;
// }

export class DialogData {
  dialogTitle: string;
  columns: any;

  /**
   * In case of confirmation dialog like deletion, updation
   */
  confirmation_message: string;
  cancelBtnLabel: string;
  saveBtnLabel: string;

  /**
   * Controls Ng Switch case, based on which different dialogs will be displayed
   */
  dialogControlType: string;

  /**
   * Holds fields
   */
  // applicationDialogQuestions: QuestionBase<any>[];

  /**
   * Hide back arrow button
   */
  hideBackArrowBtn: boolean;

  constructor(options: {
    dialogTitle?: string,
    columns?: any,
    confirmation_message?: string;
    cancelBtnLabel?: string;
    saveBtnLabel?: string;
    dialogControlType?: string;
    // applicationDialogQuestions?: QuestionBase<any>[];
    hideBackArrowBtn?: boolean;
  } = {}) {
    this.dialogTitle = options.dialogTitle || "";
    this.columns = options.columns || [];
    this.confirmation_message = options.confirmation_message || "";
    this.cancelBtnLabel = options.cancelBtnLabel || L.BTN_CANCEL;
    this.saveBtnLabel = options.saveBtnLabel || L.BTN_SAVE;
    this.dialogControlType = options.dialogControlType || L.SWITCH_CASE_TABLE_COLUMNS_SELECTION_MENU_DIALOG || snackLabel.SWITCH_CASE_CANCEL_EXPORT || snackLabel.SWITCH_CASE_REMOVE_HISTORY || snackLabel.SWITCH_CASE_RESET_HAQ_FILTER
    // this.applicationDialogQuestions = options.applicationDialogQuestions || [];
    this.hideBackArrowBtn = options.hideBackArrowBtn || true;
  }
}

@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})
export class DialogContentComponent implements OnInit {

  // checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  // disabled = false;

  @ViewChild("applicationDialogForm")
  // applicationDialogForm!: DynamicFormComponent;

  @Output() onSaveApplicationForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBackArrowBtnClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFieldValueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // private logger: NGXLogger,
  ) { }

  // @Input() dialogTitle: string = "";

  ngOnInit(): void {
  }

  onSaveApplication(event: Event) {
    // console.debug("onSaveApplication: event: ", event);
    this.onSaveApplicationForm.emit(event);
  }

  onBackArrowBtnClick(event: Event) {
    // console.debug("onBackArrowBtnClick: event: ", event);
    this.onBackArrowBtnClicked.emit(event);
  }

  onFieldValueChangeFormFunc(event: Event) {
    // console.debug("onFieldValueChangeFormFunc: event: ", event);
    this.onFieldValueChange.emit(event);
  }

}
