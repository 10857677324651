<div class="preference-container" [formGroup]="preferenceForm" *ngIf="dataLoaded">
    <div class="inner-preference-container">
        <div class="preference-titles">
            <h3>{{preferenceConstant.USER_PREFERENCE}}</h3>
            <p>{{preferenceConstant.PREFERENCE_NOTE}}</p>
        </div>
        <mat-tab-group mat-align-tabs="start">
            <!--Market Preference component-->
            <mat-tab label={{preferenceConstant.MARKET_PREFERENCE}} *ngIf="markets?.length > 0">
                <ng-template matTabContent>
                    <div class="add-border" formArrayName="markets">
                        <div *ngFor="let marketPreference of markets.controls;  let i = index">
                            <ng-container [formGroupName]="i">
                                <mat-checkbox formControlName="checked"
                                              class="checkbox-margin"
                                              (change)="onMarketChanges()">{{marketPreference.get('name').value}}</mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <!--Product Type Preference component-->
            <mat-tab label={{preferenceConstant.PRODUCT_TYPE_PREFERENCE}} *ngIf="productTypes?.length > 0">
                <ng-template matTabContent>
                    <div class="add-border" formArrayName="productTypes">
                        <div *ngFor="let productTypePreference of productTypes.controls; let i = index">
                            <ng-container [formGroupName]="i">
                                <mat-checkbox
                                        formControlName="checked" (change)="onProductTypeChanges()"
                                        class="checkbox-margin">{{productTypePreference.get('name').value}}</mat-checkbox>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <!--Therapeutic Preference component-->
            <mat-tab label={{preferenceConstant.THERAPEUTIC_PREFERENCE}}>
                <ng-template matTabContent>
                    <div class="add-border">
                        <div class="search-therapeutic">
                            <mat-form-field appearance="outline">
                                <mat-label>Search Therapeutic Area</mat-label>
                                <!-- <mat-icon aria-hidden="false" aria-label="Example search icon">search</mat-icon> -->
                                <input matInput placeholder="Ex: Diabetes" value="" autocomplete="off"
                                       formControlName="therapeuticAreaSearchInput">
                            </mat-form-field>
                        </div>
                        <div class="add-custom-scrollbar add-fix-height" formArrayName="therapeuticAreas">
                            <div *ngFor="let therapeuticPreference of therapeuticAreas.controls; let i = index">
                                <ng-container [formGroupName]="i">
                                    <mat-checkbox
                                            formControlName="checked" (change)="onTherapeuticAreaChanges()"
                                            class="checkbox-margin">{{therapeuticPreference.get('name').value}}</mat-checkbox>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>

        <div class="preference-buttons">
            <button mat-button class="reset"
                    (click)="onReset()"
                    [disabled]="!isApplyAndResetEnabled">{{preferenceConstant.RESET_PREFERENCE}}</button>
            <button mat-raised-button class="apply"
                    (click)="onApply()"
                    [disabled]="!isApplyAndResetEnabled">{{preferenceConstant.APPLY_PREFERENCE}}</button>
        </div>
    </div>
</div>
