/**
 * Created by nagaraj.ambig on 2/21/2022 5:55 PM.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ButtonActions, SectionConstant } from '../../constant/section.constant';

export interface resetSearchResultsTableAttributesInterface {
    sourcePage:
    SectionConstant.PAGE_TYPE_ADVANCED_SEARCH |
    SectionConstant.PAGE_TYPE_COMPARE_LABEL |
    SectionConstant.PAGE_TYPE_SEARCH_RESULTS |
    SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE |
    SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE |
    "none";
    resetAttributeValues: boolean
}

export interface FiltersRequiredInterface {
    drugs?: any[];
    companies?: any[];
    drugGenerics?: any[];
    marketingCategories?: any[];
}

export interface myListFiltersUpdatesInterface {
    action: ButtonActions.NONE | ButtonActions.BTN_APPLY | ButtonActions.BTN_RESET | ButtonActions.BTN_CANCEL;
    filtersObj: FiltersRequiredInterface
}

@Injectable({ providedIn: 'root' })
export class SearchSharedService {
    // private searchAttributes = new Subject<Record<string, any>>();
    private searchAttributes = new BehaviorSubject<Record<string, any>>({});

    public searchAttributes$ = this.searchAttributes.asObservable();

    setSearchAttributes(searchAttributes: Record<string, any>): void {
        this.searchAttributes.next(searchAttributes);
    }

    getSearchAttributes() {
        return this.searchAttributes
    }

    public viewMySelectedList = new BehaviorSubject<boolean>(false);
    public viewMySelectedListAsObservable = this.viewMySelectedList.asObservable()

    public unselectAllCheckboxes = new BehaviorSubject<boolean>(false);
    public unselectAllCheckboxesAsObservable = this.unselectAllCheckboxes.asObservable()

    /**
     * Used to update the data table attributes to its initial values
     */
    private removeAttributeValuesObj: resetSearchResultsTableAttributesInterface = {
        sourcePage: 'none',
        resetAttributeValues: false
    }
    public removeTableDataAttributeValues = new BehaviorSubject<resetSearchResultsTableAttributesInterface>(this.removeAttributeValuesObj);
    public removeTableDataAttributeValuesAsObservable = this.removeTableDataAttributeValues.asObservable();

    private myListFiltersUpdatesObj: myListFiltersUpdatesInterface = {
        action: ButtonActions.NONE,
        filtersObj: {}
    }

    public filterMySavedList = new BehaviorSubject<myListFiltersUpdatesInterface>(this.myListFiltersUpdatesObj);
    public filterMySavedListAsObservable = this.filterMySavedList.asObservable()

    public onMoreFilterBtnClick = new BehaviorSubject<boolean>(false);
    public onMoreFilterBtnClickAsObservable = this.onMoreFilterBtnClick.asObservable()

    private recentSearchSubscription = new BehaviorSubject<boolean>(false);
    public recentSearchSubscription$ = this.recentSearchSubscription.asObservable();
    setRecentSearchSubscription(value: boolean): void {
        this.recentSearchSubscription.next(value);
    }
}
