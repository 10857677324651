import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {keycloakConfig} from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class KeycloakApiService {
    constructor(private http: HttpClient) {
    }

    async validateToken(token: string): Promise<any> {
        const {realm, url, clientId} = keycloakConfig;
        const keyclaokEndPointUrl = `${url}realms/${realm}/protocol/openid-connect/userinfo`;

        const response = await fetch(keyclaokEndPointUrl, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }
}
