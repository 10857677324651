import {Component} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {CommonUtility} from "./common/utility/common.utility";
import {CommonConstant} from "./common/constant/common.constant";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'CRS-regulatory-intel-reg-labelling-ui';

    constructor(private keycloakService: KeycloakService) {
    }

    ngOnInit(): void {
        if (!this.authenticated) {
            this.keycloakService.login().then(async () => {
                const id = this.keycloakService.getKeycloakInstance().subject;
                CommonUtility.setUserDetails({[CommonConstant.KEY_USER_ID]: id});
            });

        }
    }

    authenticated(): boolean {
        return this.keycloakService.getKeycloakInstance().authenticated;
    }

    getPageType(): string {
        const currentUrl = location.href;
        let currentPage = '';
        if (currentUrl.search('/compare-and-export-labels') >= 0) {
            currentPage = 'compare-and-export-labels';
        }
        return currentPage;
    }

}
