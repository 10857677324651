import * as _ from 'lodash';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {CommonUtility} from "../common/utility/common.utility";
import {KeycloakService} from "keycloak-angular";
import {LocalStorageConstant} from "../common/constant/local-storage.constant";
import {KeycloakApiService} from "../common/service/keycloak-api..service";
import {
    ExportLabelEventSharedService
} from "./labelling-intelligence/compare-and-export-label/common/service/shared-service/export-label-event.shared.service";
import {
    resetSearchResultsTableAttributesInterface,
    SearchSharedService
} from '../common/service/shared-service/search.shared.service';
import {SectionConstant} from '../common/constant/section.constant';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    userName = '';
    pageTitle = '';
    mainSideBarLinks = ["Regulatory Intelligence", "Labelling Intelligence", "HAQ"];
    RILinks = ["Master Landing Page", "RI Dashboard", "Regulatory Synopsis", "Country Tables", "FAQ"];
    LILinks = ["Master Landing Page", "Labelling Dashboard", "My Library"];
    HAQLinks = ["Master Landing Page", "HAQ Dashboard"]
    isSideBarOpen = false;
    menuItemValue = "";
    disableExportButton = false;
    isExportButtonClicked = false;

    constructor(private router: Router,
                private keycloakService: KeycloakService,
                private keycloakApiService: KeycloakApiService,
                private exportLabelEventSharedService: ExportLabelEventSharedService,
                private searchSharedService: SearchSharedService) {
        this.router.events.subscribe(event => {
            this.setPageTitle(event);
        })
    }

    setPageTitle(event: any): void {
        if (event instanceof NavigationEnd) {
            if (event.url.search('/search/') >= 0) {
                this.pageTitle = 'Home';
            } else if (event.url.search('/haq-dashboard') >= 0) {
                this.pageTitle = 'HAQ Analytics Dashboard';
            } else if (event.url.search('/compare-and-export-labels') >= 0) {
                this.pageTitle = 'Compare Labels';
            } else if (event.url.search('/compare-labels') >= 0) {
                this.pageTitle = 'compare-labels';
            } else {
                this.pageTitle = '';
            }
            this.onCloseSideBarMenu();
        }
    }

    async onClickExportButton(): Promise<void> {
        this.exportLabelEventSharedService.setExportLabelEvent({isExportButtonClicked: true});
        this.isExportButtonClicked = true;
        localStorage.setItem(LocalStorageConstant.KEY_EXPORT_BUTTON_CLICK, `${true}`)
    }


    async ngOnInit() {
        // once user logs in clear the last active module
        CommonUtility.deleteItemFromLocalStorage(LocalStorageConstant.KEY_LAST_ACTIVE_MODULE_BEFORE_LOGOUT);
        try {
            const profile = await this.keycloakService.loadUserProfile(true);
            this.userName = `${profile['firstName']} , ${profile['lastName']}`;
        } catch (e) {
            throw e;
        }

        this.keycloakService.keycloakEvents$.subscribe(async (event: any) => {
            const {type} = event;
            // if token expired then do auto log out(do not clear local storage), type == 6 means token expired
            if (6 === type) {
                // just before log out set the last active module
                this.setLastActiveModule();
                await this.keycloakService.logout();
            }
        })


        document.addEventListener('visibilitychange', async () => {
            const token = await this.keycloakService.getToken();
            try {
                const {error} = await this.keycloakApiService.validateToken(token);
                if (error) {
                    await this.keycloakService.logout();
                }
            } catch (ex) {
                await this.keycloakService.logout();
                throw ex;
            }
        });

        this.exportLabelEventSharedService.exportLabelEvent$.subscribe((event) => {
            const {disableExportButton = null} = event;
            if (disableExportButton != null) {
                this.disableExportButton = disableExportButton;
            }
        })

        const getEventFromHome = localStorage.getItem(LocalStorageConstant.KEY_EXPORT_BUTTON_CLICK)
        this.exportLabelEventSharedService.exportLabelViewEventFromHome$.subscribe((value) => {
            this.isExportButtonClicked = getEventFromHome ? true : value;
        })
    }


    async logout() {
        // just before the logout set the last active module
        this.setLastActiveModule();
        //if user logs out(manually)  then clear local storage and do log out
        CommonUtility.clearLocalStorage();
        await this.keycloakService.logout();
    }

    getUserNameOfRequiredFormat(): string {
        const userDetails = CommonUtility.getUserDetailsFromLocalStorage();
        return `${_.capitalize(userDetails.firstName.charAt(0))}${_.capitalize(userDetails.lastName.charAt(0))}`;
    }

    onOpenSideBarMenu() {
        this.isSideBarOpen = true;
        let sideBarContainer = document.getElementById("sidebar-panel");
        sideBarContainer.classList.add('add-open-state');
    }

    onCloseSideBarMenu() {
        this.isSideBarOpen = false;
        let sideBarContainer = document.getElementById("sidebar-panel");
        sideBarContainer.classList.remove('add-open-state');
    }

    onClickMenuItem(linkVal) {
        this.menuItemValue = linkVal;
    }

    /**
     * Set the last active module based on the current url
     */
    setLastActiveModule(): void {
        const currentUrl = location.href;
        let currentActiveModule = 'regulatory';
        if (currentUrl.search('/haq/') >= 0) {
            currentActiveModule = 'haq';
        } else if (currentUrl.search('/regulatory/') >= 0) {
            currentActiveModule = 'regulatory';
        } else if (currentUrl.search('/labelling/') >= 0) {
            currentActiveModule = 'labelling';
        }

        CommonUtility.setItemIntoLocalStorage(LocalStorageConstant.KEY_LAST_ACTIVE_MODULE_BEFORE_LOGOUT, currentActiveModule);
    }

    onRedirectAdvanceSearch() {
        this.disableExportButton = false;
        localStorage.removeItem(LocalStorageConstant.KEY_IS_EXPORT_LABEL_VIEW);
        localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_LABEL_PAGE_INDEX);
        localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_LABEL_SELECTIONS);
        let obj: resetSearchResultsTableAttributesInterface = {
            sourcePage: SectionConstant.PAGE_TYPE_COMPARE_LABEL,
            resetAttributeValues: true
        }
        CommonUtility.setPreviousPageType(SectionConstant.PAGE_TYPE_COMPARE_LABEL);
        this.searchSharedService.removeTableDataAttributeValues.next(obj);

        CommonUtility.setLastVisitedPageType(SectionConstant.PAGE_TYPE_COMPARE_LABEL);

        //this.location.back();
        if(this.isExportButtonClicked){
            localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_BUTTON_CLICK)
            this.exportLabelEventSharedService.setExportLabelViewEvent(false)
            CommonUtility.deleteItemFromLocalStorage(LocalStorageConstant.KEY_EXPORT_LABEL_PAGE_INDEX);
            CommonUtility.deleteItemFromLocalStorage(LocalStorageConstant.KEY_IS_EXPORT_LABEL_VIEW);
            this.isExportButtonClicked = false;

            this.exportLabelEventSharedService.setExportLabelEvent({
            disableExportButton: false
        })
        } else{
            if (CommonUtility.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) this.router.navigateByUrl('/intel-reg/labelling/search/advanced');
            else this.router.navigateByUrl('/intel-reg/labelling/search/history');

        }


    }
}
