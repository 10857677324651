<div class="flex">
    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
        <!--        <div [ngStyle]="{'color':isSuccess ? '#2EAD40' : '#ff0000f0'}">
            <span *ngIf="!isSuccess" class="material-icons">highlight_off</span>
            <span *ngIf="isSuccess" class="material-icons">task_alt</span>
        </div>-->
        <div *ngIf="isProgress; else basicLayout" class="snack-progress">
            <div *ngIf="isHide && !data.exported" fxLayout="row" fxLayoutAlign="space-between center"
                class="hide-section">
                <mat-spinner [diameter]="diameter"></mat-spinner>
                <div class="data-text">{{data.message}}</div>
                <div class="hide_snackbar" (click)="hideNotification($event)">{{snackLabel?.BTN_HIDE}}</div>
                <div class="cancel_snackbar" (click)="cancelExport($event)">{{snackLabel?.BTN_CANCEL}}</div>
            </div>
            <div *ngIf="!isHide && !data.exported" fxLayout="row" fxLayoutAlign="center center" class="unhide-section">
                <div (click)="hideNotification($event)">
                    <mat-icon>more_horiz</mat-icon>
                </div>
            </div>
            <div *ngIf="data.exported" fxLayout="row" fxLayoutAlign="space-between center" class="hide-section">
                <img [src]="dropdownImagePath + 'checkmark_success.svg'" alt="" />
                <div class="data-text">{{data.message}}</div>
                <button mat-stroked-button [matMenuTriggerFor]="iWantToMenu" #menuStatus="matMenuTrigger" aria-label="iWantToMenu"
                    class="export-iwant-button" (click)="$event.stopPropagation()">
                    I Want To&nbsp;
                    <img [src]="dropdownImagePath + 'down_arrow.svg'" alt="" [ngClass]="menuStatus.menuOpen && 'up_arrow'" />
                </button>
                <mat-menu #iWantToMenu="matMenu" overlayPanelClass="mat-menu-custom-class">
                    <div *ngFor="let eMenuElem of wantToList">
                        <button mat-menu-item class="menu-item" (click)="menuClkHandler(eMenuElem)"
                            *ngIf="!eMenuElem.hidden">
                            <span *ngIf="eMenuElem.imgPath">
                                <img class="close-1" style="position: relative; top: 4px;"
                                    [src]="dropdownImagePath + eMenuElem.imgPath" alt="" />
                            </span>
                            &nbsp;
                            <span class="menu-item-label">{{eMenuElem.description}}</span>
                        </button>
                    </div>
                </mat-menu>
            </div>
        </div>

        <ng-template #basicLayout>
            <div class="data-text">{{data}}</div>
        </ng-template>
        <!--        <div class="dismiss" [ngStyle]="{'color':isSuccess ? '#2EAD40' : '#ff0000f0'}">
            <button mat-icon-button (click)="snackBarRef.dismiss()">
                <mat-icon>close</mat-icon>
            </button>
        </div>-->
    </div>

</div>

<!--<h1 mat-dialog-title>Dialog with elements</h1>
2EAD40
<div mat-dialog-content>This dialog showcases the title, close, content and actions elements.</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>-->