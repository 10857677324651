import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EllipsisModule} from 'ngx-ellipsis';

const modules = [
    EllipsisModule,
    FormsModule,
    ReactiveFormsModule];

@NgModule({
    declarations: [],
    imports: modules,
    exports: modules,

})
export class SharedModule {
}
