import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from "../../../common/service/shared-service/loader.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loaderSubscription: Subscription;
  diameter = 40;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.loaderSubscription = this.loaderService.loaderSubscription$.subscribe((value) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

}
