import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./common/guard/auth/auth.guard";
import {PreferenceGuard} from "./common/guard/preference/preference.guard";

const routes: Routes = [
    {path: '', redirectTo: `intel-reg`, pathMatch: 'full'},
    {
        path: 'intel-reg',
        loadChildren: () => import('./home/home.module')
            .then(m => m.HomeModule)
            .catch(err => console.error(err)),
        canActivate: [AuthGuard]
    },
    {
        path: 'preferences',
        loadChildren: () => import('./preference/preference.module')
            .then(m => m.PreferenceModule)
            .catch(err => console.log(err)),
        canActivate: [AuthGuard, PreferenceGuard]
    },
    {path: '**', redirectTo: `intel-reg`, pathMatch: 'full'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
