import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FollowUnfollowSharedService {

  private folloUnFollowSubscription = new BehaviorSubject<Record<string, any>>({});

  public folloUnFollowSubscription$ = this.folloUnFollowSubscription.asObservable();

  setFollowUnFollowSubscription(value: Record<string, any>): void {
    this.folloUnFollowSubscription.next(value);
  }
}
