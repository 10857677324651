import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {ServiceHelper} from './service.helper';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {preferenceConfig} from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PreferenceService {
    constructor(private http: HttpClient) {
    }

    getPreferences(userId: string): Observable<any> {
        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(preferenceConfig);
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}/${userId}`;
        return serviceHelper.get();
    }

    updatePreferences(userId: string, preferences: Record<string, any>): Observable<any> {
        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(preferenceConfig);
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.body = preferences;
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}/${userId}`;
        return serviceHelper.post();
    }
}
