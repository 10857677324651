<div class="snackbar-container">
    <div class="main-container" [ngClass]="{'display-inline': data.sameCombination == true}">
        <ng-container *ngIf="data.sameCombination; else diffCombinationBlock" class="">
            <div class="message">{{data.message | titlecase}}</div>
            <div class="close-btn">
                <img src="../../../../assets/common/Close-Small.svg" alt="" (click)="closeSnackBar()"/>
            </div>
        </ng-container>
        <ng-template #diffCombinationBlock>
            <span class="labels-followed">
                Label(s) followed
            </span>
            <span class="labels-followed-msg">
                {{data.message}}
            </span>
            <span class="OK-Got-it" (click)="closeSnackBar()">
                OK Got it!
            </span>
        </ng-template>
    </div>
    <div class="unfollow-block" *ngIf="follow && data.sameCombination">
        <button mat-button class="unfollow" (click)="unfollow()">{{label.BTN_UNFOLLOW}}</button>
    </div>
</div>