import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubscription = new BehaviorSubject<boolean>(false);

  public loaderSubscription$ = this.loaderSubscription.asObservable();

  setloaderSubscription(value: boolean): void {
    this.loaderSubscription.next(value);
  }
}
