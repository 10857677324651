export const environment = {
    production: false
};

export const appConfig = {
    enableKeycloak: false
};

export const keycloakConfig = {
    "realm": "intelireg-uat",
    "url": "https://intelireg-idp.indegene.com:8443/auth/",
    "clientId": "Crs-regulatory-intel-reg-labelling-uat-ui",
};

export const authConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/authentication/token"
};

export const labelConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/labels"

};

export const searchHistoryConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/search-history"

};

export const myListConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/my-list"
};

export const followLabelConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/follow-labels"
};

export const sectionConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/sections"
};

export const drugClassConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/drug-classes"
};

export const ndcCodeConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/ndc-codes"
};

export const drugGenericConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/drug-generics"
};


export const drugConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/drugs"
};

export const companyConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-management.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/companies"
};

export const synonymConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/synonyms"
};

export const basicSearchConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/basic-search"
};

export const exportLabelConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/export-label"
};

export const preferenceConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/user-preferences"

};

export const productEventConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/product-events"
};

export const haqFilterConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/haq/filters"
};

export const haqResultConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/haq/queries"
};

export const haqAnalyticConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/haq/analytics"
};

export const labellingAdvancedSearchFilterConfig = {
    "protocol": "https",
    "slashes": true,
    "hostname": "api-labelling.intelireg-uat.indegene.com",
    "pathname": "intel-reg/api/v1.0/labelling-intelligence/advanced-search-filters"
};
