export const Label = {
    FILTER_BY: "Filter By",
    SELECT_REGION: "Select Region",
    SEARCH_BY_KEYWORD: "Search By Keyword",
    SEARCH_BY_PLACEHOLDER: "Search by Drug, Drug Class, Generic Name",
    SEARCH_BRAND_NAME_PLACEHOLDER: "Search Brand",
    SEARCH_SECTIONS_PLACEHOLDER: "Search Sections",
    SEARCH_BY_OTHER_PLACEHOLDER: "Search Other",
    SEARCH_MANUFACTURER_NAME_PLACEHOLDER: "Search Manufacturer",
    SEARCH_GENERIC_NAME_PLACEHOLDER: "Search Generic Name",
    SEARCH_LABEL_TITLE_PLACEHOLDER: "Search Label Title",
    SEARCH_DOSAGE_FORM_PLACEHOLDER: "Search Dosage Form",
    SEARCH_DRUG_CLASS_PLACEHOLDER: "Search Drug Class",
    SEARCH_NDC_CODES_PLACEHOLDER: "Search NDC Code",
    SEARCH_SECTION_PLACEHOLDER: "Search Section",
    SEARCH_ADVANCE_INNER_SEARCH_PLACEHOLDER: "What are You looking for?",
    SELECT_ADVANCE_CONTAINS_LABEL: "Contains",
    SELECT_ADVANCE_SYNONYMS_LABEL: "Synonyms",
    SELECT_ADVANCE_LOGICAL_LABEL: "And",
    SELECT_ALL: "Select All",
    UN_SELECT_ALL: "Unselect All",
    NOT_SELECTED: "Not Selected"
}

export const MoreFilterPageLabel = {
    SEARCH_COMPANY: "Search Company",
    SEARCH_DRUG: "Search Drugs",
    SEARCH_BRAND: "Search Brands",
    SEARCH_GENERIC: "Search Generics",
    SEARCH_DOSAGE: "Search Dosage",
    SEARCH_VERSIONS: "Search Versions",
    SEARCH_MARKETING_CATEGORY: "Search Marketing Category",
    SEARCH_STATUS: "Search Status",
}

export const SearchLabel = {
    SEARCH_RESET: "RESET",
    SEARCH_RESULTS: "SEARCH RESULTS",
    VIEW_SELECTED: "View Selected Values"
}

export const SearchResultsPageLabel = {
    MSG_SEARCH_RESULTS: "Search Results",
    MSG_MY_LISTS: "My Lists",
    YOU_CAN_SELECT_UPTO: "You Can Select Upto",
    SEARCH_RESULTS_TO_COMPARE: "Search Results To Compare",
    MY_LIST_MESSAGE: "The list is also available during compare",
    SEARCH_RESULTS_FILTER_INPUT_LABEL: "Search by Brand Name, Company Name, Status",
    MORE_FILTER: "MORE FILTERS",
    MANAGER_FILTERS: "MANAGER FILTERS",
    VIEW_MY_LIST: "VIEW MY LIST",
    BACK_TO_SEARCH_RESULTS: "BACK TO SEARCH RESULTS",
    NO_SEARCH_RESULTS_AVAILABLE: "No search results available",
    APPLY_FILTERS_TO_VIEW_RESULTS: "Create search to view results",
    MANAGE_FILTERS: "Manage Filters",
    SHOW_ONLY_APPLIED_FILTER: "Show only applied filter",
    MSG_ADD_TO_LIST: `"ADD TO LIST" If you wish to compare more than "03" labels`,
}

export const ButtonLabel = {
    BTN_CANCEL: "CANCEL",
    BTN_RESET: "RESET",
    BTN_APPLY: "APPLY",
    BTN_PROCEED: "PROCEED",
    BTN_UNFOLLOW: "UNFOLLOW"
}

export const SnackLabel = {
    BTN_CANCEL: "Cancel",
    BTN_HIDE: "Hide",
    SWITCH_CASE_CANCEL_EXPORT: "SWITCH_CASE_CANCEL_EXPORT",
    EXPORT_CANCELLED: "CANCELLED",
    SWITCH_CASE_REMOVE_HISTORY: "SWITCH_CASE_REMOVE_HISTORY",
    SWITCH_CASE_RESET_HAQ_FILTER: "SWITCH_CASE_RESET_HAQ_FILTER"
}

export const SearchHistoryLabel = {
    BTN_SAVE: "Save",
    BTN_REMOVE: "Remove",
    DATE_RANGE: "Date Range",
    MANUFACTURER: "Manufacturer",
    DRUG_CLASS: "Drug Class",
    NDC_CODES: "NDC Code",
    RESULT_PER_PAGE: 5
}

export const HAQLabel = {
    QUERY_DISCARD: "DISCARD",
    QUERY_PUBLISH: "PUBLISH"
}