export const LITERALS = {
    BTN_CANCEL: "Cancel",
    BTN_SAVE: "Save",
    BTN_OKAY: "Okay",
    BTN_SEARCH: "Search",
    BTN_EXPORT: "Export",
    BTN_SUBMIT: "Submit",
    SWITCH_CASE_TABLE_COLUMNS_SELECTION_MENU_DIALOG: "SWITCH_CASE_TABLE_COLUMNS_SELECTION_MENU_DIALOG",
    SWITCH_CASE_ROW_CONFIRMATION_FOR_OPERATION_DIALOG: "SWITCH_CASE_ROW_CONFIRMATION_FOR_OPERATION_DIALOG",
    TABLE_MENU_SELECT_COLUMNS_TO_DISPLAY: "TABLE_MENU_SELECT_COLUMNS_TO_DISPLAY",
}



