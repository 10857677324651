/**
 * Created by Nagaraj.ambig on 11/8/2020 4:09 PM.
 */
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {format} from 'url';
import {Observable, throwError} from 'rxjs';
import * as _ from 'lodash';

export class ServiceHelper {

    // tslint:disable-next-line:variable-name
    private _headers: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:variable-name
    private _query: Record<any, any> = {};
    // tslint:disable-next-line:variable-name
    private _body: any = null;
    // tslint:disable-next-line:variable-name
    private _serviceConfig: object = {};
    // tslint:disable-next-line:variable-name
    private _options: Record<any, any> = {};

    public constructor(public http: HttpClient) {
    }

    // @ts-ignore
    get headers(): HttpHeaders {
        return this._headers;
    }

    // @ts-ignore
    set headers(value: HttpHeaders) {
        this._headers = value;
    }

    // @ts-ignore
    get query(): Record<string, any> {
        return this._query;
    }

    // @ts-ignore
    set query(value: Record<string, any>) {
        this._query = _.assign({}, this._query, value);
    }

    // @ts-ignore
    get body(): any {
        return this._body;
    }

    // @ts-ignore
    set body(value: any) {
        this._body = value;
    }

    // @ts-ignore
    get serviceConfig(): Record<string, any> {
        return this._serviceConfig;
    }

    // @ts-ignore
    set serviceConfig(value: Record<string, any>) {
        this._serviceConfig = value;
    }

    // @ts-ignore
    get options(): any {
        return this._options;
    }

    // @ts-ignore
    set options(value: any) {
        this._options = value;
    }


    getOptions(): object {
        const options = {};
        if (this.headers) {
            options['headers'] = this.headers;
        }
        return _.assign({}, options, this.options);
    }

    getServiceUrl(): string {
        this.serviceConfig.query = this.query;
        return format(this.serviceConfig);
    }

    post(): Observable<any> {
        return this.http.post(this.getServiceUrl(), this.body, this.getOptions());
    }

    put(): Observable<any> {
        return this.http.put(this.getServiceUrl(), this.body, this.getOptions());
    }

    get(): Observable<any> {
        return this.http.get(this.getServiceUrl(), this.getOptions());
    }
    delete(): Observable<any> {
        return this.http.delete(this.getServiceUrl(), this.getOptions());
    }

    public handleErrors(error: HttpErrorResponse): any {
        return throwError(error);
    }
}
