<div [ngSwitch]="data.dialogControlType">

    <button mat-icon-button class="close-button" aria-label="Close" matTooltip="Close" [mat-dialog-close]="null">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>

    <span [hidden]="data.hideBackArrowBtn">
        <button mat-icon-button class="back-button" aria-label="Go Back" matTooltip="Go Back"
            (click)="onBackArrowBtnClick($event)">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </span>

    <span *ngSwitchCase="'SWITCH_CASE_TABLE_COLUMNS_SELECTION_MENU_DIALOG'">
        <h2 mat-dialog-title>{{data.dialogTitle}}</h2>
        <mat-dialog-content class="mat-typography">
            <div *ngFor="let eColumn of data.columns" [hidden]="eColumn.hiddenDialogItem">
                <section class="example-section">
                    <mat-checkbox class="example-margin" [(ngModel)]="eColumn.checked" [(indeterminate)]="indeterminate"
                        [labelPosition]="labelPosition" [disabled]="eColumn.disableDialogItem"
                        [hidden]="eColumn.hiddenDialogItem">
                        {{eColumn.header}}
                    </mat-checkbox>
                </section>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{data.cancelBtnLabel}}</button>
            <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{data.saveBtnLabel}}</button>
        </mat-dialog-actions>
    </span>

    <span *ngSwitchCase="'SWITCH_CASE_ROW_CONFIRMATION_FOR_OPERATION_DIALOG'">
        <h2 mat-dialog-title>{{data.dialogTitle}}</h2>
        <mat-dialog-content class="mat-typography">
            {{data.confirmation_message}}
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>{{data.cancelBtnLabel}}</button>
            <button mat-button [mat-dialog-close]="data" cdkFocusInitial>{{data.saveBtnLabel}}</button>
        </mat-dialog-actions>
    </span>

    <span *ngSwitchCase="'SWITCH_CASE_CANCEL_EXPORT'">
        <h2 mat-dialog-title class="cancel-export-title">{{data.dialogTitle}}</h2>
        <mat-dialog-content class="cancel-export-content">
            {{data.confirmation_message}}
        </mat-dialog-content>
        <mat-dialog-actions align="end" class="cancel-export-actions">
            <button mat-button mat-dialog-close>{{data.cancelBtnLabel}}</button>
            <button mat-button [mat-dialog-close]="data" class="proceed-button" cdkFocusInitial>{{data.saveBtnLabel}}</button>
        </mat-dialog-actions>
    </span>

    <span *ngSwitchCase="'SWITCH_CASE_REMOVE_HISTORY'">
        <h2 mat-dialog-title class="remove-history-title">{{data.dialogTitle}}</h2>
        <mat-dialog-content class="remove-history-content">
            {{data.confirmation_message}}
        </mat-dialog-content>
        <mat-dialog-actions align="end" class="remove-history-actions">
            <button mat-button mat-dialog-close>{{data.cancelBtnLabel}}</button>
            <button mat-button [mat-dialog-close]="data" class="proceed-button" cdkFocusInitial>{{data.saveBtnLabel}}</button>
        </mat-dialog-actions>
    </span>

    <span *ngSwitchCase="'SWITCH_CASE_RESET_HAQ_FILTER'">
        <h2 mat-dialog-title class="reset-haq-filter-title">{{data.dialogTitle}}</h2>
        <mat-dialog-content class="reset-haq-filter-content">
            {{data.confirmation_message}}
        </mat-dialog-content>
        <mat-dialog-actions align="end" class="reset-haq-filter-actions">
            <button mat-button mat-dialog-close>{{data.cancelBtnLabel}}</button>
            <button mat-button [mat-dialog-close]="data" class="proceed-button" cdkFocusInitial>{{data.saveBtnLabel}}</button>
        </mat-dialog-actions>
    </span>

    <!-- <span *ngSwitchCase="'SWITCH_CASE_APPLICATION_RECORD_ASSIGNMENT_DIALOG'">
        <h2 mat-dialog-title>{{data.dialogTitle}}</h2>

        <mat-dialog-content class="mat-typography">

            <span *ngIf="data.confirmation_message">
                {{data.confirmation_message}}
            </span>

            <br>
            <app-dynamic-form #applicationDialogForm [questions]="data.applicationDialogQuestions"
                (callParentFunction)="onSaveApplication($event)"
                (callParentOnFieldValueChange)="onFieldValueChangeFormFunc($event)">
            </app-dynamic-form>

        </mat-dialog-content>
    </span> -->

</div>