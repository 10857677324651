/**
 * Created by nagaraj.ambig on 10/29/2020 12:05 AM.
 */

export enum ResponseConstant {
    KEY_STATUS_CODE = 'statusCode',
    KEY_MESSAGE = 'message',
    KEY_DATA = 'data',
    KEY_TOKEN = 'token',
    KEY_USER_DETAILS = 'userDetails',
    KEY_ROLE = 'role',
    KEY_TOTAL_RESULTS = 'totalResults',
    KEY_RESULTS = 'results',
    KEY_HAS_MORE_RESULTS = 'hasMoreResults',
    KEY_LAST_RESULT_ID = 'lastResultId',
}
