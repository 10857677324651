/**
 * Created by nagaraj.ambig on 11/24/2020 11:21 AM.
 */

export enum CommonConstant {
    KEY_USER_ID = 'userId',
    KEY_TOKEN = 'token',
    KEY_ID = '_id',
    KEY_FIRST_NAME = 'firstName',
    KEY_CREATED_AT = 'createdAt',
    KEY_STATUS = 'status',
    KEY_USER_DETAILS = 'userDetails',
    KEY_PRODUCT_APPROVAL = 'product-approval',
    KEY_PRODUCT_RECALL = 'product-recall',
    KEY_PRODUCT_WARNING = 'product-warning',
    KEY_PRODUCT_MEETING = 'product-meeting',
    KEY_PRODUCT_GUIDANCE = 'product-guidance',
    KEY_COMPETITIVE_INTELLIGENCE = 'competitive-intelligence',
}
