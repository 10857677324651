/**
 * Created by nagaraj.ambig on 10/29/2020 12:08 AM.
 */

import * as _ from 'lodash';
import { ResponseConstant } from '../constant/response.constant';
import { HttpHeaders } from '@angular/common/http';
import { CommonConstant } from '../constant/common.constant';
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../component/snackbar/snackbar.component";
import { AppConstant, SectionConstant } from '../constant/section.constant';
import * as moment from "moment";
import { LocalStorageConstant } from "../constant/local-storage.constant";

export interface CompareLabelDetails {
    [SectionConstant.SHOW_ALL_SECTIONS_TOGGLE_VALUE]?: boolean;
    [SectionConstant.LABELS_SEQUENCE_IN_COMPARE_PAGE]?: LabelsSequenceInComparePageInterface;
}

export interface SearchResultsDetails {
    [SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS]?: SectionConstant.PAGE_TYPE_SEARCH_RESULTS | SectionConstant.PAGE_TYPE_MY_LIST;
    [SectionConstant.DATA_SEARCH_RESULTS]?: any[];

    [SectionConstant.DATA_SELECTED_LABELS_IN_TABLE]?: any[];
    [SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE]?: boolean;
    [SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE]?: number;
    [SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER]?: number;

    [SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED]?: any[];
    [SectionConstant.DATA_MY_SAVED_LIST]?: any[];
    [SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED]?: any[];

    [SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE]?: object;
    [SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE]?: object;

    [SectionConstant.PREVIOUS_PAGE_TYPE]?: string;
    [SectionConstant.PAGE_TYPE_OF_SEARCH]?: SectionConstant.PAGE_TYPE_SEARCH_BY;
}

export interface RecentSearchResultsDetails {
    [SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS]?: SectionConstant.PAGE_TYPE_SEARCH_RESULTS | SectionConstant.PAGE_TYPE_MY_LIST;
    [SectionConstant.DATA_SEARCH_RESULTS]?: any[];

    [SectionConstant.DATA_SELECTED_LABELS_IN_TABLE]?: any[];
    [SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE]?: boolean;
    [SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE]?: number;
    [SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER]?: number;

    [SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED]?: any[];
    [SectionConstant.DATA_MY_SAVED_LIST]?: any[];
    [SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED]?: any[];

    [SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE]?: object;
    [SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE]?: object;

    [SectionConstant.PREVIOUS_PAGE_TYPE]?: string;
    [SectionConstant.PAGE_TYPE_OF_SEARCH]?: SectionConstant.PAGE_TYPE_RECENT_SEARCH;
}

export interface AppDetails {
    [SectionConstant.LAST_VISITED_PAGE_TYPE]?: SectionConstant.PAGE_TYPE_COMPARE_LABEL,
    searchResultsDetails?: SearchResultsDetails,
    compareLabelDetails?: CompareLabelDetails,
    recentSearchResultsDetails?: RecentSearchResultsDetails
}

export interface LabelsSequenceInComparePageInterface {
    labelsIndex: number;
    setId: string;
}


export class CommonUtility {

    static isSuccessResponse(responseCode: number): boolean {
        return +(responseCode) === 200;
    }

    static getCommonHeaders(headers: HttpHeaders): HttpHeaders {
        return headers.set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${CommonUtility.getTokenFromLocalStorage()}`);
    }

    public static openSnackBar(snackBar: MatSnackBar, data: string) {
        snackBar.openFromComponent(SnackbarComponent, {
            data: data,
            duration: 2500,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        });
    }

    public static openProgressSnackBar(snackBar: MatSnackBar, data: any) {
        snackBar.openFromComponent(SnackbarComponent, {
            data: data,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'snackbar-progress'
        });
    }

    static getUrlPrefix(hostName: string): string {
        let prefix = '';
        if (CommonUtility.isEqualToString('dev.iss-v2.indegene.com', hostName)) {
            prefix = 'user-labelling-ui/';
        }
        return prefix;
    }

    static getCommonHeadersForFileUploading(headers: HttpHeaders): HttpHeaders {
        return headers.set('Authorization', `Bearer ${CommonUtility.getTokenFromLocalStorage()}`)
            .set('ngsw-bypass', 'true');
    }

    static getUserIdFromLocalStorage(): string {
        return CommonUtility.getUserDetailsFromLocalStorage()[CommonConstant.KEY_USER_ID];
    }

    static getTokenFromLocalStorage(): string {
        return CommonUtility.getUserDetailsFromLocalStorage()[ResponseConstant.KEY_TOKEN];
    }

    static setUserDetails(userDetails: Record<string, any>): void {
        const userDetailsExisting = CommonUtility.getUserDetailsFromLocalStorage();
        const userDetailsRequired = { ...userDetailsExisting, ...userDetails }
        localStorage.setItem(ResponseConstant.KEY_USER_DETAILS, JSON.stringify(userDetailsRequired));
    }

    static getRoleFromLocalStorage(): string {
        return CommonUtility.getUserDetailsFromLocalStorage()[ResponseConstant.KEY_ROLE];
    }

    static getUserDetailsFromLocalStorage(): Record<string, any> {
        const userDetails = localStorage.getItem(ResponseConstant.KEY_USER_DETAILS);
        return !_.isEmpty(userDetails) ? JSON.parse(userDetails) : {};
    }

    static handleFailureCase(snackbarService: any, spinnerService: any, response, errorMessage = ''): void {
        spinnerService.hide();
        if (errorMessage) {
            snackbarService.openSnackBar(errorMessage);
        } else {
            snackbarService.openSnackBar(response.message);
        }
    }

    static isEqualToString(string1: string, string2: string): boolean {
        return _.toUpper(_.trim(string1)) === _.toUpper(_.trim(string2));
    }

    public static mapToObject(mapToBeConverted: Map<string, any>): Record<string, any> {
        if (_.isEmpty(mapToBeConverted)) {
            return mapToBeConverted;
        }
        const obj = {};
        for (const [key, value] of mapToBeConverted) {
            obj[key] = value;
        }
        return obj;
    }

    static getValidDirtyValues(form: any): Record<any, string> {
        const dirtyValues = {};

        Object.keys(form.controls)
            .forEach(key => {
                const currentControl = form.controls[key];

                if (currentControl.dirty && CommonUtility.isEqualToString("VALID", currentControl[CommonConstant.KEY_STATUS])) {
                    if (currentControl.controls) {
                        dirtyValues[key] = CommonUtility.getValidDirtyValues(currentControl);
                    } else {
                        dirtyValues[key] = currentControl.value;
                    }
                }
            });

        return dirtyValues;
    }

    static setQueryParams(pageNumber: number, resultsPerPage: number, hasPagination: boolean, lastResultId: string): any {
        const queryParams = {
            pageNumber: pageNumber,
            resultsPerPage: resultsPerPage,
            hasPagination: hasPagination,
            lastResultId: lastResultId
        };
        return queryParams;
    }


    /**
     * Show All Sections Toggle Value storage
     */
    static setShowAllSectionsToggleValue(bool: boolean): void {
        let appDetails: AppDetails = Object.assign({ compareLabelDetails: {} }, this.getAppDetails());
        appDetails.compareLabelDetails[SectionConstant.SHOW_ALL_SECTIONS_TOGGLE_VALUE] = bool;
        this.setAppDetails(appDetails);
    }

    static getShowAllSectionsToggleValue(): boolean {
        let appDetails: AppDetails = this.getAppDetails();
        const bool = appDetails?.compareLabelDetails?.[SectionConstant.SHOW_ALL_SECTIONS_TOGGLE_VALUE];
        return !_.isEmpty(bool) ? bool : null;
    }

    static removeShowAllSectionsToggleValue(): void {
        let appDetails: AppDetails = this.getAppDetails();
        delete appDetails?.compareLabelDetails?.[SectionConstant.SHOW_ALL_SECTIONS_TOGGLE_VALUE];
        this.setAppDetails(appDetails);
    }

    /**
     * Search Results Page type storage
     */
    static setSearchResultsPageType(str: SectionConstant.PAGE_TYPE_SEARCH_RESULTS | SectionConstant.PAGE_TYPE_MY_LIST): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS] = str;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS] = str;
            this.setAppDetails(appDetails);
        }
    }

    static getSearchResultsPageType(): SectionConstant.PAGE_TYPE_SEARCH_RESULTS | SectionConstant.PAGE_TYPE_MY_LIST {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const str = appDetails?.searchResultsDetails?.[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS];
            return !_.isEmpty(str) ? str : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const str = appDetails?.recentSearchResultsDetails?.[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS];
            return !_.isEmpty(str) ? str : null;
        }
    }

    static removeSearchResultsPageType(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.PAGE_TYPE_OF_SEARCH_RESULTS];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Recent search history or search by page storage
     */

    static setAdvnacedSearchPage(pageType: string): void {
        localStorage.setItem("advancedSearchPage", pageType);
    }

    static getAdvnacedSearchPage(): string {
        const pageType = localStorage.getItem("advancedSearchPage");
        return pageType;
    }

    static removeAdvancedSearchPage(): void {
        localStorage.removeItem("advancedSearchPage");
    }


    /**
     * Search Results Data storage
     */
    static setSearchResultsData(arr: any[]): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_SEARCH_RESULTS] = arr;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_SEARCH_RESULTS] = arr;
            this.setAppDetails(appDetails);
        }
    }

    static getSearchResultsData(): any[] {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.searchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS];
            return !_.isEmpty(arr) ? arr : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS];
            return !_.isEmpty(arr) ? arr : null;
        }
    }

    static removeSearchResultsData(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Recent Search Payload Data storage
     */
    static setRecentSearchBody(obj): void {
        localStorage.setItem(AppConstant.RECENT_SEARCH_PAYLOAD, JSON.stringify(obj));
    }

    static getRecentSearchBody(): AppDetails {
        const obj = localStorage.getItem(AppConstant.RECENT_SEARCH_PAYLOAD);
        return !_.isEmpty(obj) ? JSON.parse(obj) : null;
    }

    static removeRecentSearchBody(): void {
        localStorage.removeItem(AppConstant.RECENT_SEARCH_PAYLOAD);
    }

    /**
     * Search Results Page Number Data storage
     */
    static setSearchResultsPageNumber(num: number): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER] = num;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER] = num;
            this.setAppDetails(appDetails);
        }

    }

    static getSearchResultsPageNumber(): number {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const num = appDetails?.searchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER];
            return +num ? +num : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const num = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER];
            return +num ? +num : null;
        }
    }

    static removeSearchResultsPageNumber(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SEARCH_RESULTS_PAGE_NUMBER];
            this.setAppDetails(appDetails);
        }

    }

    /**
     * My Saved List data storage
     */
    static setMySavedListData(arr: any[]): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST] = arr;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST] = arr;
            this.setAppDetails(appDetails);
        }
    }

    static getMySavedListData(): any[] {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST];
            return !_.isEmpty(arr) ? arr : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST];
            return !_.isEmpty(arr) ? arr : null;
        }
    }

    static removeMySavedListData(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * My Saved List data storage after filters applied
     */
    static setMySavedListAfterFiltersAppliedData(arr: any[]): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED] = arr;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED] = arr;
            this.setAppDetails(appDetails);
        }
    }

    static getMySavedListAfterFiltersAppliedData(): any[] {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED];
            return !_.isEmpty(arr) ? arr : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED];
            return !_.isEmpty(arr) ? arr : null;
        }
    }

    static removeMySavedListAfterFiltersAppliedData(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * My Saved List data storage before filters applied
     */
    static setMySavedListBeforeFiltersAppliedData(arr: any[]): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED] = arr;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED] = arr;
            this.setAppDetails(appDetails);
        }
    }

    static getMySavedListBeforeFiltersAppliedData(): any[] {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED];
            return !_.isEmpty(arr) ? arr : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED];
            return !_.isEmpty(arr) ? arr : null;
        }
    }

    static removeMySavedListBeforeFiltersAppliedData(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Selected setId of labels in table data storage
     */
    static setSelectedLabelsInTableData(arr: any[]): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE] = arr;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE] = arr;
            this.setAppDetails(appDetails);
        }
    }

    static getSelectedLabelsInTableData(): any[] {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.searchResultsDetails?.[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE];
            return !_.isEmpty(arr) ? arr : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const arr = appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE];
            return !_.isEmpty(arr) ? arr : null;
        }
    }

    static removeSelectedLabelsInTableData(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.DATA_SELECTED_LABELS_IN_TABLE];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Remove User preferences on Fresh Advanced Search
     */
    static removeUserPreferences() {
        this.removeSearchResultsPageType();
        this.removeSearchResultsData();
        this.removeRecentSearchBody();
        this.removeMySavedListData();
        this.removeSelectedLabelsInTableData();
        this.removeShowAllSectionsToggleValue();
        this.removeHasMoreResultsOfLabels();
        this.removeTotalResultsOfLabels();
        this.removeSearchResultsPageNumber();

        /**
         * Preferences used in More Filters page
         */
        this.removeAppliedFiltersOnMyList();

        this.removeMySavedListBeforeFiltersAppliedData();
        this.removeMySavedListAfterFiltersAppliedData();

        this.removeMoreFiltersForSearchResultsPage();
        this.removeMoreFiltersForMyListPage();
        this.removePreviousPageType();

        /**
         * Remove App details
         */
        this.removeLastVisitedPageType();
        this.removeShowAllSectionsToggleValue();
        //this.removeAppDetails();
    }

    static setSearchAttributes(searchAttributes: Record<string, any>) {
        localStorage.setItem('searchAttributes', JSON.stringify(searchAttributes));
    }

    static getSearchAttributes(): any {
        // return localStorage.getItem('searchAttributes');
        const obj = localStorage.getItem("searchAttributes");
        return !_.isEmpty(obj) ? JSON.parse(obj) : null;
    }

    /**
     * Is Advanced Search section is expanded or not
     */
    static setAdvanceSearchSectionExpanded(bool: boolean): void {
        localStorage.setItem(SectionConstant.SECTION_ADVANCE_SEARCH_EXPANDED, JSON.stringify(bool));
    }

    static getAdvanceSearchSectionExpanded(): boolean {
        const bool = localStorage.getItem(SectionConstant.SECTION_ADVANCE_SEARCH_EXPANDED);
        return !_.isEmpty(bool) ? JSON.parse(bool) : null;
    }

    static removeAdvanceSearchSectionExpanded(): void {
        localStorage.removeItem(SectionConstant.SECTION_ADVANCE_SEARCH_EXPANDED);
    }

    /**
     * HasMoreResults labels in table data storage
     */
    static setHasMoreResultsOfLabels(bool: boolean): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE] = bool;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE] = bool;
            this.setAppDetails(appDetails);
        }
    }

    static getHasMoreResultsOfLabels(): boolean {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const bool = appDetails?.searchResultsDetails?.[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE];
            return typeof bool === 'boolean' ? bool : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const bool = appDetails?.recentSearchResultsDetails?.[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE];
            return typeof bool === 'boolean' ? bool : null;
        }
    }

    static removeHasMoreResultsOfLabels(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * HasMoreResults labels in table data storage
     */
    static setTotalResultsOfLabels(num: number): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE] = num;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE] = num;
            this.setAppDetails(appDetails);
        }
    }

    static getTotalResultsOfLabels(): number {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const num = appDetails?.searchResultsDetails?.[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE];
            return !_.isEmpty("" + num) ? +num : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const num = appDetails?.recentSearchResultsDetails?.[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE];
            return !_.isEmpty("" + num) ? +num : null;
        }
    }

    static removeTotalResultsOfLabels(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Previous page type data storage
     */
    static setPreviousPageType(str: string): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.PREVIOUS_PAGE_TYPE] = str;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.PREVIOUS_PAGE_TYPE] = str;
            this.setAppDetails(appDetails);
        }
    }

    static getPreviousPageType(): string {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const str = appDetails?.searchResultsDetails?.[SectionConstant.PREVIOUS_PAGE_TYPE];
            return !_.isEmpty(str) ? str : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const str = appDetails?.recentSearchResultsDetails?.[SectionConstant.PREVIOUS_PAGE_TYPE];
            return !_.isEmpty(str) ? str : null;
        }
    }

    static removePreviousPageType(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.PREVIOUS_PAGE_TYPE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.PREVIOUS_PAGE_TYPE];
            this.setAppDetails(appDetails);
        }
    }

    static updateUnFollowStatus(mySavedList: any[], setId: any[]) {
        if (CommonUtility.getMySavedListData() !== null) {
            mySavedList = mySavedList.map(e => {
                if (setId.some(inE => inE === e.setId)) e.isFollowed = false;
                e.select = false;
                return e;
            });
            CommonUtility.setMySavedListData(mySavedList);
            return mySavedList;
        }
        return;
    }

    static updateFollowStatus(mySavedList: any[], setId: any[]) {
        if (CommonUtility.getMySavedListData() !== null) {
            mySavedList = mySavedList.map(e => {
                if (setId.some(inE => inE.setId === e.setId)) e.isFollowed = true;
                e.select = false;
                return e;
            });
            CommonUtility.setMySavedListData(mySavedList);
            return mySavedList;
        }
        return;
    }

    /**
     * More filters for Search Results Page
     */
    static setMoreFiltersForSearchResultsPage(obj: object): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE] = obj;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE] = obj;
            this.setAppDetails(appDetails);
        }
    }

    static getMoreFiltersForSearchResultsPage(): object {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.searchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE];
            return !_.isEmpty(obj) ? obj : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.recentSearchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE];
            return !_.isEmpty(obj) ? obj : null;
        }
    }

    static removeMoreFiltersForSearchResultsPage(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * More filters for My List Page
     */
    static setMoreFiltersForMyListPage(obj: object): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE] = obj;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE] = obj;
            this.setAppDetails(appDetails);
        }
    }

    static getMoreFiltersForMyListPage(): object {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.searchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE];
            return !_.isEmpty(obj) ? obj : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.recentSearchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE];
            return !_.isEmpty(obj) ? obj : null;
        }
    }

    static removeMoreFiltersForMyListPage(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.MORE_FILTERS_FOR_MY_LIST_PAGE];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * Applied filters for My List
     */
    static setAppliedFiltersOnMyList(obj: object): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = Object.assign({ searchResultsDetails: {} }, this.getAppDetails());
            appDetails.searchResultsDetails[SectionConstant.APPLIED_FILTERS_ON_MY_LIST] = obj;
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = Object.assign({ recentSearchResultsDetails: {} }, this.getAppDetails());
            appDetails.recentSearchResultsDetails[SectionConstant.APPLIED_FILTERS_ON_MY_LIST] = obj;
            this.setAppDetails(appDetails);
        }
    }

    static getAppliedFiltersOnMyList(): object {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.searchResultsDetails?.[SectionConstant.APPLIED_FILTERS_ON_MY_LIST];
            return !_.isEmpty(obj) ? obj : null;
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            const obj = appDetails?.recentSearchResultsDetails?.[SectionConstant.APPLIED_FILTERS_ON_MY_LIST];
            return !_.isEmpty(obj) ? obj : null;
        }
    }

    static removeAppliedFiltersOnMyList(): void {
        if (this.getAdvnacedSearchPage() === SectionConstant.PAGE_TYPE_SEARCH_BY) {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.searchResultsDetails?.[SectionConstant.APPLIED_FILTERS_ON_MY_LIST];
            this.setAppDetails(appDetails);
        } else {
            let appDetails: AppDetails = this.getAppDetails();
            delete appDetails?.recentSearchResultsDetails?.[SectionConstant.APPLIED_FILTERS_ON_MY_LIST];
            this.setAppDetails(appDetails);
        }
    }

    /**
     * App details
     */
    static setAppDetails(obj: AppDetails): void {
        localStorage.setItem(AppConstant.APP_DETAILS, JSON.stringify(obj));
    }

    static getAppDetails(): AppDetails {
        const obj = localStorage.getItem(AppConstant.APP_DETAILS);
        return !_.isEmpty(obj) ? JSON.parse(obj) : null;
    }

    static removeAppDetails(): void {
        localStorage.removeItem(AppConstant.APP_DETAILS);
    }

    /**
     * lastVisitedPageType data storage
     */
    static setLastVisitedPageType(str: SectionConstant.PAGE_TYPE_COMPARE_LABEL): void {
        let appDetails: AppDetails = Object.assign({}, this.getAppDetails());
        appDetails[SectionConstant.LAST_VISITED_PAGE_TYPE] = str;
        this.setAppDetails(appDetails);
    }

    static getLastVisitedPageType(): SectionConstant.PAGE_TYPE_COMPARE_LABEL {
        let appDetails: AppDetails = this.getAppDetails();
        const str = appDetails?.[SectionConstant.LAST_VISITED_PAGE_TYPE];
        return !_.isEmpty(str) ? str : null;
    }

    static removeLastVisitedPageType(): void {
        let appDetails: AppDetails = this.getAppDetails();
        delete appDetails?.[SectionConstant.LAST_VISITED_PAGE_TYPE];
        this.setAppDetails(appDetails);
    }

    static clearLocalStorage(): void {
        localStorage.removeItem(ResponseConstant.KEY_USER_DETAILS);
        localStorage.removeItem(SectionConstant.SECTION_ADVANCE_SEARCH_EXPANDED);
        localStorage.removeItem('searchAttributes');
        localStorage.removeItem(AppConstant.APP_DETAILS);
        localStorage.removeItem('hasSetPreferences');
        localStorage.removeItem('tabIndex');
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE);
        localStorage.removeItem(LocalStorageConstant.isProductApprovalDateChanged);
        localStorage.removeItem(LocalStorageConstant.isRecallDateChanged);
        localStorage.removeItem(LocalStorageConstant.isWarningDateChanged);
        localStorage.removeItem(LocalStorageConstant.isGuidanceDateChanged);
        localStorage.removeItem(LocalStorageConstant.isCommitteeDateChanged);
        localStorage.removeItem(LocalStorageConstant.isCompetitiveDateChanged);
        localStorage.removeItem(LocalStorageConstant.COMPETITIVE_FROM_DATE);
        localStorage.removeItem(LocalStorageConstant.COMPETITIVE_TO_DATE);
        localStorage.removeItem(LocalStorageConstant.BROWSE_BY_TYPE);
        localStorage.removeItem(LocalStorageConstant.UPCOMING_MEETING_TYPE);
        localStorage.removeItem(LocalStorageConstant.RECENT_MEETING_TYPE);
        localStorage.removeItem(LocalStorageConstant.UPCOMING_MEETING_COUNT);
        localStorage.removeItem(LocalStorageConstant.RECENT_MEETING_COUNT);
        localStorage.removeItem(LocalStorageConstant.PRODUCT_UPCOMING_COUNT);
        localStorage.removeItem(LocalStorageConstant.PRODUCT_RECENT_COUNT);
        localStorage.removeItem(LocalStorageConstant.KEY_PAGE_TYPE);
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_APPROVAL_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_RECALL_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_WARNING_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_GUIDANCE_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_PRODUCT_MEETING_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_COMPETITIVE_INTELLIGENCE_PREFERENCE);
        localStorage.removeItem(LocalStorageConstant.KEY_RI_DASHBOARD_PREFERENCE_FILTER_INITIAL);
        localStorage.removeItem(LocalStorageConstant.KEY_SEARCH_RESULTS_FROM_HISTORY);
        localStorage.removeItem(LocalStorageConstant.KEY_SORT_EVENT_MY_LIST);
        localStorage.removeItem(LocalStorageConstant.KEY_SORT_EVENT_SEARCH_RESULTS);
        localStorage.removeItem(LocalStorageConstant.KEY_HAQ_QUERY_FILTERS);
        localStorage.removeItem(LocalStorageConstant.KEY_EVENT_CLICKED_ROW);
        localStorage.removeItem(LocalStorageConstant.RECENT_HISTORY_CLICKED_PAGENUMBER);
        this.removeRecentSearchBody();
        this.removeAdvancedSearchPage();
        this.removeAppDetails();
        localStorage.removeItem(LocalStorageConstant.KEY_HAQ_ANALYTIC_FILTERS);
        localStorage.removeItem(LocalStorageConstant.KEY_HAQ_QUERY_FILTERS_SELECTED);
        localStorage.removeItem(LocalStorageConstant.KEY_HAQ_ANALYTIC_FILTERS_SELECTED);
        this.removeBasicSearchCheckedItems();
        this.removebasicSearchSupressedFilters();
        localStorage.removeItem(LocalStorageConstant.KEY_IS_EXPORT_LABEL_VIEW);
        localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_LABEL_PAGE_INDEX);
        localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_LABEL_SELECTIONS);
        localStorage.removeItem(LocalStorageConstant.KEY_EXPORT_BUTTON_CLICK);
    }

    static getDefaultDateRangeForProductEvents(): Record<string, any> {
        return { startDate: moment(new Date()).subtract(14, 'days'), endDate: moment(new Date()) }
    }

    static getDateRangeOfRequiredFormatForProductEvents(dateRange: Record<string, any>): Record<string, any> {
        const { startDate, endDate } = dateRange;
        return {
            startDate: `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
            endDate: `${moment(endDate).format('YYYY-MM-DD')}T23:59:59.999Z`
        }
    }

    static setSelectedDateRangeForProductEvents(key: string, dateRange: Record<string, any>): void {
        const existingProductEventsEntry = localStorage.getItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE);
        if (existingProductEventsEntry) {
            const existingProductEventsEntryParsed = JSON.parse(existingProductEventsEntry);
            localStorage.setItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE, JSON.stringify({
                ...existingProductEventsEntryParsed,
                [key]: dateRange
            }))
        } else {
            localStorage.setItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE, JSON.stringify({
                [key]: dateRange
            }));
        }
    }

    static getSelectedDateRangeOfProductEvent(key: string): Record<string, any> {
        const entry = localStorage.getItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE);
        if (entry && !_.isEmpty(JSON.parse(entry))) {
            return JSON.parse(entry)[key];
        }
        return null;
    }

    static deleteDateRangeOfProductEvent(key: string): void {
        const entry = localStorage.getItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE);
        if (entry && !_.isEmpty(JSON.parse(entry))) {
            const entryParsed = JSON.parse(entry);
            delete entryParsed[key];
            localStorage.setItem(LocalStorageConstant.KEY_PRODUCT_EVENTS_DATE_RANGE, JSON.stringify(entryParsed))
        }
    }

    static getDateStringOfMoment(momentDate: any): any {
        return moment(momentDate).toDate().toDateString();
    }

    /**
     * Sequence of Labels to display in Compare Labels Page
     */
    static setLabelsSequenceInComparePage(obj: LabelsSequenceInComparePageInterface): void {
        let appDetails: AppDetails = Object.assign({ compareLabelDetails: {} }, this.getAppDetails());
        appDetails.compareLabelDetails[SectionConstant.LABELS_SEQUENCE_IN_COMPARE_PAGE] = obj;
        this.setAppDetails(appDetails);
    }

    static getLabelsSequenceInComparePage(): LabelsSequenceInComparePageInterface {
        let appDetails: AppDetails = this.getAppDetails();
        const obj = appDetails?.compareLabelDetails?.[SectionConstant.LABELS_SEQUENCE_IN_COMPARE_PAGE];
        return !_.isEmpty(obj) ? obj : null;
    }

    static removeLabelsSequenceInComparePage(): void {
        let appDetails: AppDetails = this.getAppDetails();
        delete appDetails?.compareLabelDetails?.[SectionConstant.LABELS_SEQUENCE_IN_COMPARE_PAGE];
        this.setAppDetails(appDetails);
    }

    static getSelectedAttributes(items: Record<string, any>[]): Record<string, any>[] {
        return _.filter(items, (item) => {
            return item.checked === true;
        })
    }

    static getSelectedSynonyms(items: Record<string, any>[]): Record<string, any> {
        return _.filter(items, (item) => {
            return item.checked === true && item.isSynonym === true;
        })
    }

    static findAvailableIndex(previousList: any[], _id: any): number {
        if (previousList.length > 0) {
            const index = previousList.findIndex((s) => s._id === _id);
            return index;
        }
        return -1;
    }

    static getBasicSearchAttributes(searchAttributes: Record<string, any>): Record<string, any> {
        const isBasicSearchApplied = searchAttributes.basicSearchFormGroup.isBasicSearchApplied;
        let drugGenerics = [], drugs = [], drugClasses = [];
        if (isBasicSearchApplied) {
            drugGenerics = CommonUtility.getSelectedAttributes(searchAttributes.basicSearchFormGroup.generics);
            drugs = CommonUtility.getSelectedAttributes(searchAttributes.basicSearchFormGroup.drugs);
            drugClasses = CommonUtility.getSelectedAttributes(searchAttributes.basicSearchFormGroup.drugClasses);
        }
        return { drugGenerics, drugs, drugClasses }
    }

    static getAdvancedSearchAttributes(searchAttributes: Record<string, any>): Record<string, any>[] {
        const advancedSearchAttributesRequired = [];
        _.each(searchAttributes.advancedSearchFormGroup.advancedSearchSections, (item) => {
            const { othersGroup, sectionGroup, isApplied } = item.advancedSearchGroup;
            let { synonyms, colorCode, synonymSearchTerm } = item.synonymsGroup;
            const {
                revisionDate, drugBrandGroup, manufacturerGroup, drugGenericGroup,
                dosageFormGroup, drugClassGroup, ndcCodeGroup
            } = othersGroup;

            const drugBrands = CommonUtility.getSelectedAttributes(drugBrandGroup.drugBrands);
            const companies = CommonUtility.getSelectedAttributes(manufacturerGroup.manufacturers);
            const drugGenerics = CommonUtility.getSelectedAttributes(drugGenericGroup.drugGenerics);
            const dosageForms = CommonUtility.getSelectedAttributes(dosageFormGroup.dosageForms);
            const drugClasses = CommonUtility.getSelectedAttributes(drugClassGroup.drugClasses);
            const ndcCodes = CommonUtility.getSelectedAttributes(ndcCodeGroup.ndcCodes);
            const sections = CommonUtility.getSelectedAttributes(sectionGroup.sections);
            const searchTerm = CommonUtility.getSelectedSynonyms(synonyms);
            colorCode = !_.isEmpty(colorCode) ? colorCode[0] : '';
            if (!!synonymSearchTerm) {
                searchTerm.push({ name: synonymSearchTerm, isSynonym: false, checked: true })
            }

            if (isApplied) {
                advancedSearchAttributesRequired.push({
                    revisionDate: this.getDatesOfRequiredFormat(revisionDate), drugBrands, companies, drugGenerics,
                    dosageForms, drugClasses, ndcCodes, sections, searchTerm, colorCode
                });
            } else if (!!searchTerm) {
                advancedSearchAttributesRequired.push({ searchTerm, colorCode });
            }
        })
        return advancedSearchAttributesRequired;
    }

    static getDatesOfRequiredFormat(revisionDate: Record<string, any>) {
        if (revisionDate.startDate && revisionDate.endDate) {
            const startDate = new Date(revisionDate.startDate);
            const endDate = new Date(revisionDate.endDate);
            return {
                startDate: `${startDate.getFullYear()}-${this.getMonthOfRequiredFormat(startDate.getMonth())}-${this.getDateOfRequiredFormat(startDate.getDate())}T00:00:00.000Z`,
                endDate: `${endDate.getFullYear()}-${this.getMonthOfRequiredFormat(endDate.getMonth())}-${this.getDateOfRequiredFormat(endDate.getDate())}T23:59:59.999Z`,
            }
        }
        return revisionDate;
    }

    static getMonthOfRequiredFormat(monthIndex): string {
        if (monthIndex < 9) {
            return `0${monthIndex + 1}`
        }
        return monthIndex + 1;
    }

    static getDateOfRequiredFormat(date: number): any {
        if (date < 10) {
            return `0${date}`
        }
        return date;
    }


    static sortArrayOfObjects(arr: Record<string, any>[], fieldName, direction): any {
        if (direction === 'asc') {
            arr.sort((a, b) => a[fieldName].toLowerCase() > b[fieldName].toLowerCase() ? 1
                : b[fieldName].toLowerCase() > a[fieldName].toLowerCase() ? -1 : 0
            );
        } else {
            arr.sort((a, b) => a[fieldName].toLowerCase() > b[fieldName].toLowerCase() ? -1
                : b[fieldName].toLowerCase() > a[fieldName].toLowerCase() ? 1 : 0
            );
        }
        return arr;
    }

    static sortArrayOfObjectsforDatesString(arr: Record<string, any>[], fieldName, direction): any {
        if (direction === 'asc') {
            arr.sort((a, b) => new Date(a[fieldName]) > new Date(b[fieldName]) ? 1
                : new Date(b[fieldName]) > new Date(a[fieldName]) ? -1 : 0
            )
        } else {
            arr.sort((a, b) => new Date(a[fieldName]) > new Date(b[fieldName]) ? -1
                : new Date(b[fieldName]) > new Date(a[fieldName]) ? 1 : 0
            );
        }

        return arr;
    }


    static filterArray(arrayList: any[], id: any): any[] {
        arrayList.filter((element) => {
            return element.setId !== id;
        });
        return arrayList;
    }

    static getItemFromLocalStorage(key: string): string {
        return localStorage.getItem(key);
    }

    static setItemIntoLocalStorage(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    static deleteItemFromLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }

    static getResultsCountDecimal(resultCount: any) {
        return (resultCount > 9 || resultCount === 0) ? resultCount : `0${resultCount}`;
    }

    static getDateRangeForUpcomingMeeting(): Record<string, any> {
        let defaultDateRange = this.getDefaultDateRangeForUpcomingMeeting();

        const selectedDateRange = CommonUtility.getSelectedDateRangeOfProductEvent(LocalStorageConstant.KEY_PRODUCT_MEETING_UPCOMING_DATE_RANGE);
        if (selectedDateRange?.formattedDates) {
            defaultDateRange = selectedDateRange.formattedDates;
        }
        return defaultDateRange
    }

    static getDefaultDateRangeForUpcomingMeeting(): Record<string, any> {
        return {
            startDate: `${moment().format('YYYY-MM-DD')}T00:00:00.000Z`,
            endDate: `${moment().add(14, 'days').format('YYYY-MM-DD')}T23:59:59.999Z`
        }
    }

    static getDateRangeForRecentMeeting(): Record<string, any> {
        let defaultDateRange = this.getDefaultDateRangeForRecentMeeting();

        const selectedDateRange = CommonUtility.getSelectedDateRangeOfProductEvent(LocalStorageConstant.KEY_PRODUCT_MEETING_RECENT_DATE_RANGE);
        if (selectedDateRange?.formattedDates) {
            defaultDateRange = selectedDateRange.formattedDates;
        }
        return defaultDateRange
    }

    static getDefaultDateRangeForRecentMeeting(): Record<string, any> {
        return {
            startDate: `${moment().subtract(14, 'days').format('YYYY-MM-DD')}T00:00:00.000Z`,
            endDate: `${moment().subtract(1, 'day').format('YYYY-MM-DD')}T23:59:59.999Z`
        }
    }

    static handleSelectAllStatus(component: any, elements: Record<string, any>[]): void {

        if (elements && elements.length > 0) {
            component.allSelected = _.filter(elements, (item) => {
                return item.checked;
            }).length === elements.length;

            component.noneSelected = _.filter(elements, (item) => {
                return !item.checked;
            }).length === elements.length;

            component.someSelected = !component.allSelected && !component.noneSelected;
        }
    }

    static getSelectedAttributesOfForm(formValues: Record<string, any>): Record<string, any> {
        if (_.isEmpty(formValues)) {
            return formValues
        }
        const selectedFormValues = {};
        for (let [k, v] of Object.entries(formValues)) {
            if (!!v && Array.isArray(v)) {
                selectedFormValues[k] = _.filter(v, (item) => {
                    return item.checked;
                })
            } else {
                selectedFormValues[k] = v;
            }
        }
        return selectedFormValues;
    }

    //Method for No results
    static noRecordOption(data: Record<string, any>) {
        let noRecordsOptions = {
            title: {
                show: data.length === 0,
                textStyle: {
                    color: "#66676B",
                    fontSize: 13,
                    fontWeight: 'normal'
                },
                text: "No Results To Show",
                left: "center",
                top: "center",
            }
        }
        return noRecordsOptions;
    }

    static setBasicSearchCheckedItems(items: any[]): void {
        localStorage.setItem("basicSearchCheckedItems", JSON.stringify(items));
    }

    static getBasicSearchCheckedItems(): any[] {
        const items = localStorage.getItem("basicSearchCheckedItems");
        return _.isEmpty(items) ? [] : JSON.parse(items);
    }

    static removeBasicSearchCheckedItems(): void {
        localStorage.removeItem("basicSearchCheckedItems");
    }

    static setbasicSearchSupressedFilters(items: any[]): void {
        localStorage.setItem("basicSearchSupressedFilters", JSON.stringify(items));
    }

    static getbasicSearchSupressedFilters(): any[] {
        const items = localStorage.getItem("basicSearchSupressedFilters");
        return _.isEmpty(items) ? [] : JSON.parse(items);
    }

    static removebasicSearchSupressedFilters(): void {
        localStorage.removeItem("basicSearchSupressedFilters");
    }
}


