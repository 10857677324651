/**
 * Created by nagaraj.ambig on 6/1/2021 4:43 PM.
 */

export enum MessageConstant {
  MESSAGE_COMPANY_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving company details, Please contact administrator!!!',
  MESSAGE_LABEL_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving labels, Please contact administrator!!!',
  MESSAGE_FOLLOW_LABEL_FAILED_CONTACT_ADMINISTRATOR = 'Error while following labels, Please contact administrator!!!',
  MESSAGE_UN_FOLLOW_LABEL_FAILED_CONTACT_ADMINISTRATOR = 'Error while unfollowing labels, Please contact administrator!!!',
  MESSAGE_SEARCH_HISTORY_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving search histories, Please contact administrator!!!',
  MESSAGE_SECTION_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving sections, Please contact administrator!!!',
  MESSAGE_SYNONYM_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving synonyms, Please contact administrator!!!',
  MESSAGE_DRUG_CLASSES_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving drug classes, Please contact administrator!!!',
  MESSAGE_DRUG_BRANDS_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving drug brands, Please contact administrator!!!',
  MESSAGE_ADVANCED_SEARCH_FILTERS_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving advanced search filters, Please contact administrator!!!',
  MESSAGE_BASIC_SEARCH_OPTIONS_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving basic search options, Please contact administrator!!!',
  MESSAGE_DRUG_GENERICS_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving drug generics, Please contact administrator!!!',
  MESSAGE_NDC_CODE_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving ndc codes, Please contact administrator!!!',
  MESSAGE_EXPORT_LABEL_FAILED_CONTACT_ADMINISTRATOR = 'Error while exporting labels, Please contact administrator!!!',
  MESSAGE_DOWNLOAD_LABEL_FAILED_CONTACT_ADMINISTRATOR = 'Error while downloading labels, Please contact administrator!!!',
  MESSAGE_HAQ_RESULT_COUNT_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving result count, Please contact administrator!!!',
  MESSAGE_HAQ_RESULT_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving haq results, Please contact administrator!!!',
  MESSAGE_HAQ_FILTER_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving haq filters, Please contact administrator!!!',
  MESSAGE_HAQ_ANALYTIC_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving haq analytics, Please contact administrator!!!',
  MESSAGE_COMPETITOR_MASTER_LIST_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving competitor master list, Please contact administrator!!!',
  MESSAGE_COMPETITIVE_INTELLIGENCE_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving competitive intelligence, Please contact administrator!!!',
  MESSAGE_PRODUCT_MEETING_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving product meetings, Please contact administrator!!!',
  MESSAGE_PRODUCT_EVENT_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving product events, Please contact administrator!!!',
  MESSAGE_PRODUCT_EVENT_TOTAL_RESULT_COUNT_RETRIEVAL_FAILED_CONTACT_ADMINISTRATOR = 'Error while retrieving product event total result count, Please contact administrator!!!',
  RESET_WARNING_MESSAGE='Are you sure you want to reset?',
  RESET_NOTE="Note : This action cannot be undone",
}
