import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ButtonLabel } from '../../constant/labels';
import { CommonUtility } from "../../utility/common.utility";
import { FollowLabelService } from "../../service/follow-label.service";
import { ResponseConstant } from "../../constant/response.constant";
import * as _ from 'lodash';
import { MessageConstant } from '../../constant/message.constant';
import { SearchSharedService } from '../../service/shared-service/search.shared.service';
import { FollowUnfollowSharedService } from '../../service/shared-service/follow-unfollow-shared.service';

@Component({
    selector: 'app-follow-unfollow-snackbar',
    templateUrl: './follow-unfollow-snackbar.component.html',
    styleUrls: ['./follow-unfollow-snackbar.component.scss'],
    providers: [FollowLabelService]
})
export class FollowUnfollowSnackbarComponent implements OnInit {
    label = ButtonLabel;
    userId = '';
    follow;

    constructor(public snackBarRef: MatSnackBarRef<FollowUnfollowSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private followLabelService: FollowLabelService,
        private snackbar: MatSnackBar,
        private searchSharedService: SearchSharedService,
        private followUnfollowSharedService: FollowUnfollowSharedService) {
        this.follow = data.follow;
    }

    ngOnInit(): void {
        this.userId = CommonUtility.getUserIdFromLocalStorage();
    }

    closeSnackBar() {
        this.snackBarRef.dismiss();
    }

    unfollow() {
        const setId = _.map(this.data.labelDetailsRequired, (item) => {
            return item.setId
        });
        this.followLabelService.unFollowLabels(this.userId, { setId: setId.join(",") }).subscribe({
            next: (response) => {
                if (CommonUtility.isSuccessResponse(response[ResponseConstant.KEY_STATUS_CODE])) {
                    CommonUtility.updateUnFollowStatus(CommonUtility.getMySavedListData(), setId);
                    this.reloadPage();
                } else {
                    this.reloadPage();
                    CommonUtility.openSnackBar(this.snackbar, response[ResponseConstant.KEY_MESSAGE]);
                }
            },
            error: (err) => {
                this.reloadPage();
                CommonUtility.openSnackBar(this.snackbar, MessageConstant.MESSAGE_UN_FOLLOW_LABEL_FAILED_CONTACT_ADMINISTRATOR);
            },
        });
    }

    reloadPage() {
        if (this.data.compareLabel) {
            const unfollowObj = {
                unfollow: true,
                title: this.data.labelTitle,
                labelDetailsRequired: this.data.labelDetailsRequired
            }
            this.followUnfollowSharedService.setFollowUnFollowSubscription(unfollowObj);
        } else {
            const unfollowObj = {
                searchAttributes: this.data.searchAttributes,
                unfollow: true,
                labelDetailsRequired: this.data.labelDetailsRequired
            }
            this.searchSharedService.setSearchAttributes(unfollowObj);
        }
    }
}
