/**
 * Created by nagaraj.ambig on 4/7/2022 6:31 PM.
 */

export enum LocalStorageConstant {
    KEY_COMPETITIVE_INTELLIGENCE = 'competitive-intelligence',
    KEY_PRODUCT_APPROVAL_PREFERENCE = 'product-approval-preference',
    KEY_PRODUCT_RECALL_PREFERENCE = 'product-recall-preference',
    KEY_PRODUCT_WARNING_PREFERENCE = 'product-warning-preference',
    KEY_PRODUCT_GUIDANCE_PREFERENCE = 'product-guidance-preference',
    KEY_PRODUCT_MEETING_PREFERENCE = 'product-meeting-preference',
    KEY_COMPETITIVE_INTELLIGENCE_PREFERENCE = 'competitive-intelligence-preference',
    KEY_PRODUCT_APPROVAL_DATE_RANGE = 'product-approval-date-range',
    KEY_PRODUCT_RECALL_DATE_RANGE = 'product-recall-date-range',
    KEY_PRODUCT_WARNING_DATE_RANGE = 'product-warning-date-range',
    KEY_PRODUCT_GUIDANCE_DATE_RANGE = 'product-guidance-date-range',
    KEY_HAQ_QUERY_FILTERS = 'haq-query-filters',
    KEY_HAQ_QUERY_FILTERS_SELECTED = 'haq-query-filters-selected',
    KEY_HAQ_ANALYTIC_FILTERS = 'haq-analytic-filters',
    KEY_HAQ_ANALYTIC_FILTERS_SELECTED = 'haq-analytic-filters-selected',
    KEY_PRODUCT_MEETING_UPCOMING_DATE_RANGE = 'product-meeting-upcoming-date-range',
    KEY_PRODUCT_MEETING_RECENT_DATE_RANGE = 'product-meeting-recent-date-range',
    KEY_COMPETITIVE_INTELLIGENCE_DATE_RANGE = 'competitive-intelligence-date-range',
    KEY_PAGE_TYPE = 'page-type',
    KEY_PRODUCT_MEETING_ACTIVE_TAB_INDEX = 'product-meeting-active-tab-index',
    KEY_PRODUCT_EVENTS_DATE_RANGE = 'product-events-date-range',
    KEY_RI_DASHBOARD_PREFERENCE_FILTER_INITIAL = 'ri-dashboard-preference-filter-initial',
    isProductApprovalDateChanged = 'isProductApprovalDateChanged',
    isRecallDateChanged = 'isRecallDateChanged',
    isWarningDateChanged = 'isWarningDateChanged',
    isGuidanceDateChanged = 'isGuidanceDateChanged',
    isCommitteeDateChanged = 'isCommitteeDateChanged',
    isCompetitiveDateChanged = 'isCompetitiveDateChanged',
    COMPETITIVE_FROM_DATE = "competitive-from-date",
    COMPETITIVE_TO_DATE = "competitive-to-date",
    BROWSE_BY_TYPE = "Browse By Type",
    UPCOMING_MEETING_TYPE = 'upcoming-meeting-type',
    RECENT_MEETING_TYPE = 'recent-meeting-type',
    UPCOMING_MEETING_COUNT = 'upcoming-meeting-count',
    RECENT_MEETING_COUNT = 'recent-meeting-count',
    PRODUCT_UPCOMING_COUNT = 'product-upcoming-count',
    PRODUCT_RECENT_COUNT = 'product-recent-count',
    KEY_SEARCH_RESULTS_FROM_HISTORY = 'search-results-from-history',
    KEY_SORT_EVENT_MY_LIST = 'sort-for-my-list',
    KEY_SORT_EVENT_SEARCH_RESULTS = 'sort-for-search-results',
    KEY_EVENT_CLICKED_ROW = 'clicked-row-id',
    RECENT_HISTORY_CLICKED_PAGENUMBER = 'recent-history-page-number',
    KEY_LAST_ACTIVE_MODULE_BEFORE_LOGOUT = 'last-active-module-before-log-out',
    KEY_IS_EXPORT_LABEL_VIEW = 'is-export-label-view',
    KEY_EXPORT_LABEL_PAGE_INDEX = 'export-label-page-index',
    KEY_EXPORT_LABEL_SELECTIONS = 'export-label-selections',
    KEY_EXPORT_BUTTON_CLICK = 'export-button-click',
}
