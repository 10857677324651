import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportLabelSharedService {

  private exportLabelSubscription = new BehaviorSubject<Record<string, any>>({});

  public exportLabelSubscription$ = this.exportLabelSubscription.asObservable();

  setExportLabelSubscription(value: Record<string, any>): void {
    this.exportLabelSubscription.next(value);
  }
}
