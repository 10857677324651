import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {ServiceHelper} from './service.helper';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {followLabelConfig} from "../../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class FollowLabelService {

    constructor(private http: HttpClient) {
    }

    followLabels(userId: string, body: Record<string, any>[]): Observable<any> {
        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(followLabelConfig);
        serviceHelper.body = body;
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}/${userId}`;
        return serviceHelper.post();
    }

    unFollowLabels(userId: string, queryParams: Record<string, any>): Observable<any> {

        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(followLabelConfig);
        serviceHelper.query = queryParams;
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}/${userId}`;
        return serviceHelper.delete();
    }
}
