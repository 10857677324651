<div class="header-container">
    <header fxLayout="row">
        <div class="left-panel">
            <div class="header-img">
                <img src="../../assets/home/LandingPage/IntelReg.svg" alt=""/>
            </div>
            <div *ngIf="pageTitle != 'Compare Labels'" class="title">
                <span class="home">{{pageTitle}}</span>
            </div>
        </div>
        <div class="right-panel" fxFlex fxLayoutAlign="end start">
            <img class="icon notification" src="../../assets/home/LandingPage/notification.svg" alt=""/>
            <img class="icon info" src="../../assets/home/LandingPage/info.svg" alt=""/>
            <img class="icon profile" src="../../assets/home/LandingPage/profile.jpg" alt=""
                 [matMenuTriggerFor]="menu"/>
            <mat-menu #menu="matMenu">
                <button mat-menu-item>
                    <!-- <mat-icon>account_circle</mat-icon> -->
                    <img src="../../assets/home/account_circle.svg" alt=""/>
                    <span class="icon-menu">View Profile</span>
                </button>
                <button mat-menu-item>
                    <!-- <mat-icon>build</mat-icon> -->
                    <img src="../../assets/home/settings.svg" alt=""/>
                    <span class="icon-menu">Settings</span>
                </button>
                <button mat-menu-item (click)="logout()">
                    <!-- <mat-icon>logout</mat-icon> -->
                    <img src="../../assets/home/logout.svg" alt=""/>
                    <span class="icon-menu">Logout</span>
                </button>
            </mat-menu>

        </div>
    </header>
    <div *ngIf="pageTitle=== 'Compare Labels'" style="height: 35px;
    position: relative; bottom: 5px;" fxLayout="row"
         fxLayoutAlign="space-between">
        <div style="height: 35px; display: flex;align-items: center">
            <mat-icon class="back-icon" (click)="onRedirectAdvanceSearch()">arrow_back</mat-icon>
            <span class="compare-label">{{pageTitle}}</span>
        </div>
        <div class="action-btns" fxFlexAlign="end" style="padding-right: 25px">
            <button mat-flat-button class="action-btn" (click)="onClickExportButton()" [disabled]="disableExportButton">EXPORT
            </button>
        </div>
    </div>
</div>

<div class="main-container">
    <div class="sidebar-panel" id="sidebar-panel">
          <span class="sidebar-arrow-icon" (click)="onOpenSideBarMenu()" *ngIf="!isSideBarOpen">
            <img src="../../assets/home/white_arrow.svg" alt="arrow-icon"/>
          </span>
        <span class="sidebar-arrow-icon-close" (click)="onCloseSideBarMenu()" *ngIf="isSideBarOpen">
            <img src="../../assets/home/arrow-left.svg" alt="arrow-icon"/>
          </span>
        <div class="sidebar-open-container" *ngIf="isSideBarOpen">

            <!--changes related to https://indegene-cto.atlassian.net/browse/NRI-749 -->
            <!--<ul class="sidebar-links" *ngIf="menuItemValue=== ''">
                <li *ngFor="let item of mainSideBarLinks" (click)="onClickMenuItem(item)">{{item}}</li>
            </ul>-->
            <ul class="sidebar-links" *ngIf="menuItemValue=== ''">
                <li><a routerLink="/intel-reg/regulatory/ri-dashboard">Regulatory Intelligence</a></li>
                <li><a routerLink="/intel-reg/labelling/search/advanced">Labelling Intelligence</a></li>
                <li (click)="onClickMenuItem('HAQ')">
                    <!-- <a routerLink="/intel-reg/haq/queries">HAQ</a> -->
                    <a>HAQ</a>
                </li>
            </ul>
            <!--changes related to https://indegene-cto.atlassian.net/browse/NRI-749 -->
            <div class="inner-main-links" *ngIf="menuItemValue">
                <h3 class="sidebar-item-title">
                    <img src="../../assets/common/arrow.svg" alt="Back" (click)="onClickMenuItem('')"/>
                    {{menuItemValue}}
                </h3>
                <ul *ngIf="menuItemValue === 'HAQ'">
                    <li><a routerLink="/intel-reg/haq/queries">HAQ Repository</a></li>
                    <li><a routerLink="/intel-reg/haq/haq-dashboard">HAQ Analytics Dashboard</a></li>
                </ul>

            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>

