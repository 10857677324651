import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {ServiceHelper} from './service.helper';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {exportLabelConfig} from "../../../environments/environment";

@Injectable({
    providedIn: 'root',
})
export class ExportLabelService {

    constructor(private http: HttpClient) {
    }

    exportLabels(queryParams: Record<string, any>, body: Record<string, any>[]): Observable<any> {
        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(exportLabelConfig);
        serviceHelper.body = body;
        serviceHelper.query = queryParams;
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}`;
        return serviceHelper.post();
    }

    downloadLabels(queryParams: Record<string, any>, body: Record<string, any>[]): Observable<any> {
        const serviceHelper = new ServiceHelper(this.http);
        const serviceConfig = _.cloneDeep(exportLabelConfig);
        serviceHelper.body = body;
        serviceHelper.query = queryParams;
        serviceHelper.serviceConfig = serviceConfig;
        serviceHelper.serviceConfig.pathname = `${serviceConfig.pathname}/download-exported-label`;
        serviceHelper.options.responseType = 'blob';
        return serviceHelper.post();
    }
}
