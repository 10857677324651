import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import {
    MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef,
} from "@angular/material/snack-bar";
import { ResponseConstant } from "../../constant/response.constant";
import { CommonUtility } from "../../../common/utility/common.utility";
import { MessageConstant } from "../../../common/constant/message.constant";
import { SnackLabel, ButtonLabel as buttonLabel } from "../../constant/labels";
import { MatDialog } from '@angular/material/dialog';
import { DialogContentComponent, DialogData } from '../../../common/component/dialog-content/dialog-content.component';
import { ExportLabelService } from 'src/app/common/service/export-label.service';
import { ExportLabelSharedService } from '../../../common/service/shared-service/export-label-shared.service';
export interface WantToListInterface {
    code: any;
    description: string;
    hidden: boolean;
    disabled: boolean;
    imgPath: string;
}
@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    providers: [ExportLabelService],
    encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent implements OnInit {
    isRequestInProgress = false;
    public snackLabel = SnackLabel;
    public isSuccess = true;
    public isProgress = false;
    public diameter = "27.3";
    public isHide = true;
    public isOpen = false;
    userId = '';
    dropdownImagePath: string = "../../../../assets/home/AdvancedFilterPage/";
    wantToList: WantToListInterface[] = [
        {
            code: "DOWNLOAD",
            description: 'Download',
            hidden: false,
            disabled: false,
            imgPath: "download.svg"

        }, {
            code: "SHARE",
            description: 'Share',
            hidden: false,
            disabled: false,
            imgPath: "share.svg"

        }
    ]
    constructor(
        public snackBarRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public dialog: MatDialog,
        private exportLabelService: ExportLabelService,
        private snackbar: MatSnackBar,
        private exportLabelSharedService: ExportLabelSharedService
    ) {
        this.isProgress = data?.progress === true;
        this.isSuccess = data[ResponseConstant.KEY_STATUS_CODE] === 200;
    }

    ngOnInit(): void {
        this.userId = CommonUtility.getUserIdFromLocalStorage();
    }

    hideNotification(event: Event) {
        this.isHide = !this.isHide;
    }

    menuClkHandler(event) {
        switch (event.code) {
            case "DOWNLOAD":
                this.downloadLabel();
                break;
        }
    }

    downloadLabel(): void {
        this.exportLabelService.downloadLabels({ userId: this.userId, type: this.data.type }, this.data.exportedLabel).subscribe({
            next: (response) => {
                this.snackBarRef.dismiss();
                this.isRequestInProgress = false;
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                this.data.exportedLabel && this.data.exportedLabel.length === 1 ? link.setAttribute('download', this.data.exportedLabel[0] + '.html') : link.setAttribute('download', 'labels.zip');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            },
            error: (err) => {
                this.isRequestInProgress = false;
                CommonUtility.openSnackBar(this.snackbar, MessageConstant.MESSAGE_DOWNLOAD_LABEL_FAILED_CONTACT_ADMINISTRATOR);
            },
        });
    }

    cancelExport(event: Event) {
        let dialogData: DialogData = new DialogData({
            dialogControlType: this.snackLabel.SWITCH_CASE_CANCEL_EXPORT,
            dialogTitle: 'Notification Message Goes Here.',
            confirmation_message: 'Note: This action cannot be undone',
            cancelBtnLabel: buttonLabel.BTN_CANCEL,
            saveBtnLabel: buttonLabel.BTN_PROCEED
        })
        const dialogRef = this.dialog.open(DialogContentComponent, {
            data: dialogData,
            backdropClass: "cancel-export-backdrop",
            hasBackdrop: true,
            panelClass: "cancel-export-panel"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.snackBarRef.dismiss();
                this.exportLabelSharedService.setExportLabelSubscription({ status: this.snackLabel.EXPORT_CANCELLED });
            }
        });
    }
}
