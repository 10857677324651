/**
 * Created by nagaraj.ambig on 03/15/2021 11:45 AM.
 */

export enum SectionConstant {
    SHOW_ALL_SECTIONS = 'Show All Sections',
    SHOW_ALL_SECTIONS_TOGGLE_VALUE = "show-all-sections-toggle-value",
    PAGE_TYPE_ADVANCED_SEARCH = "advanced-search-page",
    PAGE_TYPE_SEARCH_RESULTS = "search-results-page",
    PAGE_TYPE_MY_LIST = "my-list-page",
    PAGE_TYPE_SEARCH_BY = "search-by",
    PAGE_TYPE_RECENT_SEARCH = "recent-search",
    PAGE_TYPE_OF_SEARCH = "page-type-of-search",
    PAGE_TYPE_OF_SEARCH_RESULTS = "page-type-of-search-results",
    DATA_SEARCH_RESULTS = "data-search-results",
    DATA_SEARCH_RESULTS_PAGE_NUMBER = "data-search-results-page-number",
    DATA_MY_SAVED_LIST = "data-my-saved-list",
    DATA_MY_SAVED_LIST_AFTER_FILTERS_APPLIED = "data-my-saved-list-after-filters-applied",
    DATA_MY_SAVED_LIST_BEFORE_FILTERS_APPLIED = "data-my-saved-list-before-filters-applied",
    DATA_SELECTED_LABELS_IN_TABLE = "data-selected-labels-in-table",
    CHECKBOX_ALL_SECTIONS = "All Sections",
    SECTION_ADVANCE_SEARCH_EXPANDED = "section-advance-search-expanded",
    BOOL_HAS_MORE_LABEL_RESULTS_IN_TABLE = "has-more-results",
    TOTAL_SEARCH_RESULTS_COUNT_IN_TABLE = "total-search-results-count",
    PAGE_TYPE_COMPARE_LABEL = "compare-label-page",
    PREVIOUS_PAGE_TYPE = "previous-page-type",
    PREVIOUS_ADVANCED_SEARCH_APPLIED = "previous-advanced-search-applied",
    MORE_FILTERS_FOR_SEARCH_RESULTS_PAGE = "more-filters-for-search-results-page",
    MORE_FILTERS_FOR_MY_LIST_PAGE = "more-filters-for-my-list-page",
    APPLIED_FILTERS_ON_MY_LIST = "applied-filters-on-my-list",
    LAST_VISITED_PAGE_TYPE = "lastVisitedPageType",
    LABELS_SEQUENCE_IN_COMPARE_PAGE = "labels-sequence",
}

export enum ButtonActions {
    BTN_APPLY = "BTN_APPLY",
    BTN_RESET = "BTN_RESET",
    BTN_CANCEL = "BTN_CANCEL",
    NONE = "NONE",
}

export enum AppConstant {
    APP_DETAILS = "app-details",
    RECENT_SEARCH_PAYLOAD = "recent-search-payload"
}
