import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreferenceComponent } from './preference.component';
import { SharedModule } from '../common/module/shared.module';
import { CommonModule } from "@angular/common";
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

const routes: Routes = [
    {path: '', component:PreferenceComponent}
]

@NgModule({
    declarations: [
        PreferenceComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        CommonModule,
        MatTabsModule,
        MatCheckboxModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [
        PreferenceComponent,
    ]
})

export class PreferenceModule{

}
